import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from './ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  PieChart as RePieChart,
  Pie,
  Cell,
} from 'recharts';
import { BookOpen, Bookmark, RefreshCw, Book } from 'lucide-react';
import HeaderNav from './HeaderNav';
import { Badge } from './ui/badge';
import { cn } from '../lib/utils';

interface AnalyticsData {
  assignmentStats: {
    totalAssignments: number;
    completedAssignments: number;
    inProgressAssignments: number;
    passRate: number;
    partialPassCount: number;
    completedJuz: number;
  };
  mistakeStats: {
    majorMistakes: number;
    minorMistakes: number;
    tajweedMistakes: number;
  };
  monthlyProgress: {
    month: string;
    completedLessons: number;
    accuracy: number;
  }[];
  lessonTypeDistribution: {
    type: string;
    count: number;
  }[];
  weeklyStreak: {
    day: string;
    minutes: number;
    completed: boolean;
  }[];
  skillsData: {
    skill: string;
    level: number;
  }[];
  achievements: {
    title: string;
    description: string;
    earned: boolean;
    progress: number;
    icon: string;
  }[];
  learningInsights: {
    title: string;
    description: string;
    type: 'success' | 'warning' | 'info';
  }[];
  timeStats: {
    totalMinutes: number;
    averageSessionLength: number;
    bestTimeOfDay: string;
    consistencyScore: number;
  };
  attendance: {
    present: number;
    late: number;
    absent: number;
  };
  recentAssignments: {
    type: string;
    date: string;
    status: 'Pass' | 'Fail' | 'Partial' | 'Pause';
  }[];
  comparison: {
    personalRate: number;
    classRate: number;
    personalJuz: number;
    classJuz: number;
    personalAttendance: number;
    classAttendance: number;
  };
}

const ProgressChart: React.FC<{
  data: AnalyticsData['monthlyProgress'];
  isLoading: boolean;
}> = ({ data, isLoading }) => {
  return (
    <Card className="bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm border-none shadow-none">
      <CardContent className="p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
            Learning Progress
          </h2>
        </div>
        <div className="h-[300px] sm:h-[450px] w-full">
          {isLoading ? (
            <div className="h-full w-full bg-gray-200 dark:bg-gray-700 animate-pulse rounded-3xl" />
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={data}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient
                    id="progressGradient"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor="#0d9488" stopOpacity={0.1} />
                    <stop offset="95%" stopColor="#0d9488" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="month"
                  stroke="#9ca3af"
                  axisLine={false}
                  tickLine={false}
                />
                <YAxis stroke="#9ca3af" axisLine={false} tickLine={false} />
                <Area
                  type="monotone"
                  dataKey="completedLessons"
                  stroke="#0d9488"
                  fillOpacity={1}
                  fill="url(#progressGradient)"
                  strokeWidth={2}
                />
                <Area
                  type="monotone"
                  dataKey="accuracy"
                  stroke="#22c55e"
                  fill="none"
                  strokeWidth={2}
                />
                <Tooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div className="bg-white/90 backdrop-blur-sm p-2 rounded-lg shadow-lg border border-gray-100/20">
                          <p className="text-sm font-medium text-gray-900">
                            {payload[0].value}
                          </p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const AssignmentPieChart: React.FC<{
  data: { name: string; value: number }[];
}> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const COLORS = ['#22c55e', '#fbbf24', '#ef4444']; // green-500, amber-400, red-500 (softer versions)

  const dataWithColors = data.map((item, index) => ({
    ...item,
    color: COLORS[index % COLORS.length],
  }));

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  return (
    <div className="w-full h-[250px] flex items-center justify-center">
      <ResponsiveContainer width="100%" height="100%">
        <RePieChart>
          <Pie
            data={dataWithColors}
            cx="50%"
            cy="50%"
            innerRadius={70}
            outerRadius={100}
            paddingAngle={2}
            dataKey="value"
            activeIndex={activeIndex}
            onMouseEnter={onPieEnter}
          >
            {dataWithColors.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
                style={{
                  filter: index === activeIndex ? 'brightness(1.1)' : 'none',
                }}
              />
            ))}
          </Pie>
          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                return (
                  <div className="bg-white/90 backdrop-blur-sm p-2 rounded-lg shadow-lg border border-gray-100/20">
                    <p className="text-sm font-medium text-gray-900">
                      {payload[0].name}: {payload[0].value}%
                    </p>
                  </div>
                );
              }
              return null;
            }}
          />
          <Legend
            verticalAlign="bottom"
            height={36}
            content={({ payload }) => {
              if (payload && payload.length) {
                return (
                  <div className="grid grid-cols-3 gap-4 mt-4">
                    {dataWithColors.map((entry, index) => (
                      <div
                        key={`legend-${index}`}
                        className={`flex flex-col items-center p-2 rounded-xl bg-gray-50 transition-all duration-200 ${
                          index === activeIndex ? 'ring-2 ring-gray-200' : ''
                        }`}
                        onMouseEnter={() => setActiveIndex(index)}
                      >
                        <div className="flex items-center gap-2 mb-1">
                          <div
                            className="w-3 h-3 rounded-full"
                            style={{ backgroundColor: entry.color }}
                          />
                          <span className="text-sm font-medium text-gray-700">
                            {entry.name}
                          </span>
                        </div>
                        <span className="text-lg font-semibold text-gray-900">
                          {entry.value}%
                        </span>
                      </div>
                    ))}
                  </div>
                );
              }
              return null;
            }}
          />
        </RePieChart>
      </ResponsiveContainer>
    </div>
  );
};

const ComparisonBarChart: React.FC<{
  data: { category: string; personal: number; class: number }[];
}> = ({ data }) => {
  const COLORS = {
    personal: '#0f766e',
    class: '#14b8a6',
  };

  return (
    <div className="w-full h-[350px]">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          barGap={8}
          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        >
          <XAxis
            dataKey="category"
            axisLine={false}
            tickLine={false}
            tick={{ fill: 'rgb(107 114 128)', fontSize: 12 }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: 'rgb(107 114 128)', fontSize: 12 }}
            domain={[0, 100]}
          />
          <Tooltip
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                return (
                  <div className="bg-white/90 backdrop-blur-sm p-3 rounded-lg shadow-lg border border-gray-100/20">
                    <p className="text-sm font-medium text-gray-900 mb-1">
                      {label}
                    </p>
                    <p className="text-xs text-gray-600">
                      You: {payload[0].value}%
                    </p>
                    <p className="text-xs text-gray-600">
                      Class: {payload[1].value}%
                    </p>
                  </div>
                );
              }
              return null;
            }}
          />
          <Legend
            verticalAlign="top"
            height={36}
            content={({ payload }) => (
              <div className="flex justify-center gap-4 mb-4">
                <div className="flex items-center gap-1">
                  <div
                    className="w-3 h-3 rounded-full"
                    style={{ backgroundColor: COLORS.personal }}
                  />
                  <span className="text-sm text-gray-600">Your Progress</span>
                </div>
                <div className="flex items-center gap-1">
                  <div
                    className="w-3 h-3 rounded-full"
                    style={{ backgroundColor: COLORS.class }}
                  />
                  <span className="text-sm text-gray-600">Class Average</span>
                </div>
              </div>
            )}
          />
          <Bar
            dataKey="personal"
            fill={COLORS.personal}
            radius={[4, 4, 0, 0]}
            maxBarSize={50}
            name="Your Progress"
          />
          <Bar
            dataKey="class"
            fill={COLORS.class}
            radius={[4, 4, 0, 0]}
            maxBarSize={50}
            name="Class Average"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const Analytics: React.FC = () => {
  const [timeRange, setTimeRange] = useState('week');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<AnalyticsData | null>(null);

  useEffect(() => {
    // Fetch data here
    setTimeout(() => {
      setData({
        assignmentStats: {
          totalAssignments: 120,
          completedAssignments: 95,
          inProgressAssignments: 15,
          passRate: 85,
          partialPassCount: 10,
          completedJuz: 5,
        },
        mistakeStats: {
          majorMistakes: 25,
          minorMistakes: 45,
          tajweedMistakes: 30,
        },
        monthlyProgress: [
          { month: 'Jan', completedLessons: 8, accuracy: 75 },
          { month: 'Feb', completedLessons: 12, accuracy: 80 },
          { month: 'Mar', completedLessons: 15, accuracy: 85 },
          { month: 'Apr', completedLessons: 10, accuracy: 82 },
          { month: 'May', completedLessons: 18, accuracy: 88 },
          { month: 'Jun', completedLessons: 14, accuracy: 90 },
        ],
        lessonTypeDistribution: [
          { type: 'New', count: 40 },
          { type: 'Past', count: 30 },
          { type: '7 Pages', count: 25 },
          { type: 'Revision', count: 25 },
        ],
        weeklyStreak: [
          { day: 'Mon', minutes: 45, completed: true },
          { day: 'Tue', minutes: 60, completed: true },
          { day: 'Wed', minutes: 30, completed: false },
          { day: 'Thu', minutes: 55, completed: true },
          { day: 'Fri', minutes: 40, completed: true },
          { day: 'Sat', minutes: 50, completed: true },
          { day: 'Sun', minutes: 35, completed: true },
        ],
        skillsData: [
          { skill: 'Tajweed', level: 4 },
          { skill: 'Memorization', level: 3 },
          { skill: 'Recitation', level: 5 },
          { skill: 'Understanding', level: 4 },
          { skill: 'Application', level: 3 },
        ],
        achievements: [
          {
            title: 'Daily Streak',
            description: '7 days in a row',
            earned: true,
            progress: 100,
            icon: 'trophy',
          },
          {
            title: 'Weekly Goal',
            description: 'Complete 10 lessons',
            earned: false,
            progress: 50,
            icon: 'trophy',
          },
        ],
        learningInsights: [
          {
            title: 'Consistency is key',
            description: 'Try to study at the same time every day',
            type: 'success',
          },
          {
            title: 'Focus on your weaknesses',
            description: 'Improve your Tajweed skills',
            type: 'warning',
          },
        ],
        timeStats: {
          totalMinutes: 1000,
          averageSessionLength: 30,
          bestTimeOfDay: 'Morning',
          consistencyScore: 80,
        },
        attendance: {
          present: 20,
          late: 5,
          absent: 2,
        },
        recentAssignments: [
          { type: 'New', date: '2023-02-20', status: 'Pass' },
          { type: 'Past', date: '2023-02-19', status: 'Partial' },
          { type: '7 Pages', date: '2023-02-18', status: 'Fail' },
          { type: 'Revision', date: '2023-02-17', status: 'Pause' },
        ],
        comparison: {
          personalRate: 0.85,
          classRate: 0.8,
          personalJuz: 10,
          classJuz: 12,
          personalAttendance: 0.9,
          classAttendance: 0.85,
        },
      });
      setIsLoading(false);
    }, 1000);
  }, [timeRange]);

  if (isLoading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <HeaderNav title="Analytics" />
      <div className="min-h-screen bg-white pb-40">
        <div className="container mx-auto px-4">
          <div className="pt-8 pb-6 space-y-4">
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
              <div>
                <h1 className="text-3xl font-bold bg-gradient-to-r from-gray-900 to-gray-600 dark:from-white dark:to-gray-300 bg-clip-text text-transparent">
                  Analytics Dashboard
                </h1>
                <p className="text-gray-600 dark:text-gray-400 mt-1">
                  Track your Quran memorization journey
                </p>
              </div>
              <Select value={timeRange} onValueChange={setTimeRange}>
                <SelectTrigger className="w-[140px]">
                  <SelectValue placeholder="Select range" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="week">This Week</SelectItem>
                  <SelectItem value="month">This Month</SelectItem>
                  <SelectItem value="year">This Year</SelectItem>
                  <SelectItem value="all">All Time</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-8">
            <Card className="lg:col-span-2 overflow-hidden border-0 bg-gradient-to-br from-white/80 via-white/90 to-white/70 dark:from-gray-800/80 dark:via-gray-800/90 dark:to-gray-800/70 backdrop-blur-xl shadow-xl hover:shadow-2xl transition-all duration-300 rounded-3xl">
              <CardHeader className="border-b border-gray-100/50 dark:border-gray-700/50 bg-gradient-to-br from-gray-50/50 to-transparent dark:from-gray-800/50 dark:to-transparent p-6">
                <CardTitle className="text-xl font-semibold bg-gradient-to-br from-gray-900 to-gray-600 dark:from-gray-100 dark:to-gray-400 bg-clip-text text-transparent">
                  Monthly Progress
                </CardTitle>
                <CardDescription className="text-gray-500 dark:text-gray-400">
                  Assignment completion by Juz
                </CardDescription>
              </CardHeader>
              <CardContent className="p-6">
                <ProgressChart
                  data={data.monthlyProgress}
                  isLoading={isLoading}
                />
              </CardContent>
            </Card>

            <div className="space-y-6">
              <Card className="border-0 bg-gradient-to-br from-white/80 via-white/90 to-white/70 dark:from-gray-800/80 dark:via-gray-800/90 dark:to-gray-800/70 backdrop-blur-xl shadow-xl hover:shadow-2xl transition-all duration-300 rounded-3xl">
                <CardHeader className="border-b border-gray-100/50 dark:border-gray-700/50 bg-gradient-to-br from-gray-50/50 to-transparent dark:from-gray-800/50 dark:to-transparent p-6">
                  <CardTitle className="text-xl font-semibold bg-gradient-to-br from-gray-900 to-gray-600 dark:from-gray-100 dark:to-gray-400 bg-clip-text text-transparent">
                    Attendance Overview
                  </CardTitle>
                  <CardDescription className="text-gray-500 dark:text-gray-400">
                    Current Month
                  </CardDescription>
                </CardHeader>
                <CardContent className="p-6">
                  <div className="space-y-4">
                    <div className="grid grid-cols-3 gap-3">
                      {[
                        {
                          label: 'Present',
                          value: data.attendance.present,
                          color: 'emerald',
                        },
                        {
                          label: 'Late',
                          value: data.attendance.late,
                          color: 'amber',
                        },
                        {
                          label: 'Absent',
                          value: data.attendance.absent,
                          color: 'rose',
                        },
                      ].map((stat) => (
                        <div
                          key={stat.label}
                          className={cn(
                            'p-4 rounded-2xl text-center group hover:scale-105 transition-all duration-300',
                            `bg-gradient-to-br from-${stat.color}-50/90 to-${stat.color}-50/50 dark:from-${stat.color}-900/30 dark:to-${stat.color}-900/10`,
                            `border border-${stat.color}-100/50 dark:border-${stat.color}-700/30`,
                            'backdrop-blur-sm shadow-lg hover:shadow-xl'
                          )}
                        >
                          <div
                            className={`text-${stat.color}-600 dark:text-${stat.color}-400 text-2xl font-bold mb-1 group-hover:scale-110 transition-transform duration-300`}
                          >
                            {stat.value}
                          </div>
                          <div
                            className={`text-${stat.color}-600/80 dark:text-${stat.color}-400/80 text-sm font-medium`}
                          >
                            {stat.label}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="border-0 bg-gradient-to-br from-white/80 via-white/90 to-white/70 dark:from-gray-800/80 dark:via-gray-800/90 dark:to-gray-800/70 backdrop-blur-xl shadow-xl hover:shadow-2xl transition-all duration-300 rounded-3xl">
                <CardHeader className="border-b border-gray-100/50 dark:border-gray-700/50 bg-gradient-to-br from-gray-50/50 to-transparent dark:from-gray-800/50 dark:to-transparent p-6">
                  <CardTitle className="text-xl font-semibold bg-gradient-to-br from-gray-900 to-gray-600 dark:from-gray-100 dark:to-gray-400 bg-clip-text text-transparent">
                    Assignment Stats
                  </CardTitle>
                  <CardDescription className="text-gray-500 dark:text-gray-400">
                    Last 30 Days
                  </CardDescription>
                </CardHeader>
                <CardContent className="p-6">
                  <AssignmentPieChart
                    data={[
                      {
                        name: 'Pass Rate',
                        value: data.assignmentStats.passRate,
                      },
                      {
                        name: 'Partial Pass',
                        value: Math.round(
                          (data.assignmentStats.partialPassCount /
                            data.assignmentStats.totalAssignments) *
                            100
                        ),
                      },
                      {
                        name: 'Fail Rate',
                        value:
                          100 -
                          data.assignmentStats.passRate -
                          Math.round(
                            (data.assignmentStats.partialPassCount /
                              data.assignmentStats.totalAssignments) *
                              100
                          ),
                      },
                    ]}
                  />
                  <div className="mt-4 grid grid-cols-3 gap-2">
                    {[
                      {
                        label: 'Pass',
                        value: `${data.assignmentStats.passRate}%`,
                        color: 'teal',
                      },
                      {
                        label: 'Partial',
                        value: `${Math.round((data.assignmentStats.partialPassCount / data.assignmentStats.totalAssignments) * 100)}%`,
                        color: 'gray',
                      },
                      {
                        label: 'Fail',
                        value: `${100 - data.assignmentStats.passRate - Math.round((data.assignmentStats.partialPassCount / data.assignmentStats.totalAssignments) * 100)}%`,
                        color: 'gray',
                      },
                    ].map((stat, index) => (
                      <div key={index} className="text-center">
                        <div
                          className={`text-${stat.color}-600 dark:text-${stat.color}-400 font-semibold mb-2`}
                        >
                          {stat.value}
                        </div>
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          {stat.label}
                        </div>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <Card className="border-0 bg-gradient-to-br from-white/80 via-white/90 to-white/70 dark:from-gray-800/80 dark:via-gray-800/90 dark:to-gray-800/70 backdrop-blur-xl shadow-xl hover:shadow-2xl transition-all duration-300 rounded-3xl">
              <CardHeader className="border-b border-gray-100/50 dark:border-gray-700/50 bg-gradient-to-br from-gray-50/50 to-transparent dark:from-gray-800/50 dark:to-transparent p-6">
                <CardTitle className="text-xl font-semibold bg-gradient-to-br from-gray-900 to-gray-600 dark:from-gray-100 dark:to-gray-400 bg-clip-text text-transparent">
                  Assignment History
                </CardTitle>
                <CardDescription className="text-gray-500 dark:text-gray-400">
                  Recent assignment outcomes
                </CardDescription>
              </CardHeader>
              <CardContent className="p-6">
                <div className="space-y-4">
                  {data.recentAssignments.map((assignment, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-4 rounded-2xl bg-gradient-to-br from-gray-50/50 to-transparent backdrop-blur-sm hover:shadow-md transition-all duration-300"
                    >
                      <div className="flex items-center space-x-3">
                        <div className="relative p-2 rounded-xl bg-gradient-to-br from-primary-100/80 to-primary-50/50">
                          <div className="absolute inset-0 rounded-xl bg-gradient-to-br from-white/50 to-transparent" />
                          {assignment.type === 'New' && (
                            <BookOpen
                              className="relative h-5 w-5 text-primary-600"
                              strokeWidth={2}
                            />
                          )}
                          {assignment.type === 'Past' && (
                            <RefreshCw
                              className="relative h-5 w-5 text-primary-600"
                              strokeWidth={2}
                            />
                          )}
                          {assignment.type === '7 Pages' && (
                            <Book
                              className="relative h-5 w-5 text-primary-600"
                              strokeWidth={2}
                            />
                          )}
                          {assignment.type === 'Revision' && (
                            <Bookmark
                              className="relative h-5 w-5 text-primary-600"
                              strokeWidth={2}
                            />
                          )}
                        </div>
                        <div>
                          <div className="font-medium text-gray-900">
                            {assignment.type}
                          </div>
                          <div className="text-sm text-gray-500">
                            {assignment.date}
                          </div>
                        </div>
                      </div>
                      <Badge
                        variant={
                          assignment.status === 'Pass'
                            ? 'default'
                            : assignment.status === 'Fail'
                              ? 'destructive'
                              : assignment.status === 'Partial'
                                ? 'secondary'
                                : 'outline'
                        }
                        className="rounded-lg font-medium"
                      >
                        {assignment.status}
                      </Badge>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>

            <Card className="border-0 bg-gradient-to-br from-white/80 via-white/90 to-white/70 dark:from-gray-800/80 dark:via-gray-800/90 dark:to-gray-800/70 backdrop-blur-xl shadow-xl hover:shadow-2xl transition-all duration-300 rounded-3xl">
              <CardHeader className="border-b border-gray-100/50 dark:border-gray-700/50 bg-gradient-to-br from-gray-50/50 to-transparent dark:from-gray-800/50 dark:to-transparent p-6">
                <CardTitle className="text-xl font-semibold bg-gradient-to-br from-gray-900 to-gray-600 dark:from-gray-100 dark:to-gray-400 bg-clip-text text-transparent">
                  Class Comparison
                </CardTitle>
                <CardDescription className="text-gray-500 dark:text-gray-400">
                  Your progress relative to class average
                </CardDescription>
              </CardHeader>
              <CardContent className="p-6">
                <ComparisonBarChart
                  data={[
                    {
                      category: 'Completion',
                      personal: Math.round(data.comparison.personalRate * 100),
                      class: Math.round(data.comparison.classRate * 100),
                    },
                    {
                      category: 'Juz',
                      personal: Math.round(
                        (data.comparison.personalJuz / 30) * 100
                      ),
                      class: Math.round((data.comparison.classJuz / 30) * 100),
                    },
                    {
                      category: 'Attendance',
                      personal: Math.round(
                        data.comparison.personalAttendance * 100
                      ),
                      class: Math.round(data.comparison.classAttendance * 100),
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;
