'use client';

import React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from './ui/accordion';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Switch } from './ui/switch';
import { Card, CardHeader, CardContent } from './ui/card';
import { Badge } from './ui/badge';
import { Plus, Minus, ChevronDown, Search, X } from 'lucide-react';
import { Surah, surahs } from '../utils/surahUtils';
import { AssignmentMutationMode } from '../types/common';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderNav from './HeaderNav';
import { cn } from '../lib/utils';

interface LocationState {
  user: {
    id: string;
    name: string;
  };
  type: string;
}

interface VerseRange {
  startVerse: number | null;
  endVerse: number | null;
}

interface Verses {
  [key: number]: VerseRange[];
}

interface InputValues {
  [key: number]: {
    startVerse: string;
    endVerse: string;
  }[];
}

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex w-full touch-none select-none items-center',
      className
    )}
    {...props}
  >
    <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-secondary">
      <SliderPrimitive.Range className="absolute h-full bg-primary" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="block h-5 w-5 rounded-full border-2 border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
    <SliderPrimitive.Thumb className="block h-5 w-5 rounded-full border-2 border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
  </SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export default function CreateAssignment() {
  const { state } = useLocation() as { state: LocationState };
  const navigate = useNavigate();

  // State management
  const [filteredSurahs, setFilteredSurahs] = React.useState<Surah[]>(surahs);
  const [entireVerse, setEntireVerse] = React.useState<boolean[]>(
    new Array(surahs.length).fill(false)
  );
  const [verses, setVerses] = React.useState<Verses>({});
  const [inputValues, setInputValues] = React.useState<InputValues>({});
  const [errors, setErrors] = React.useState<{ [key: number]: string }>({});

  const formattedTitle = React.useMemo(() => {
    if (!state?.type) return 'Add New Lesson';
    const formattedType = state.type
      .split('_')
      .map((word) => {
        if (word.toLowerCase() === 'seven') return '7';
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
    return `Add ${formattedType} Lesson`;
  }, [state?.type]);

  // Scroll to top on mount
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Filter surahs based on search term
  const filterItems = (searchTerm: React.ChangeEvent<HTMLInputElement>) => {
    const term = searchTerm.target.value.toLowerCase();
    const filteredItems = surahs.filter((surah: Surah) =>
      surah.name.toLowerCase().includes(term)
    );
    setFilteredSurahs(filteredItems);
  };

  // Handle input change for verse numbers
  const handleInputChange = (
    surahNumber: number,
    type: 'startVerse' | 'endVerse',
    value: string,
    index: number
  ) => {
    setInputValues((prev) => {
      const currentInputs = prev[surahNumber] || [];
      const updatedInputs = [...currentInputs];
      if (!updatedInputs[index]) {
        updatedInputs[index] = { startVerse: '', endVerse: '' };
      }
      updatedInputs[index] = {
        ...updatedInputs[index],
        [type]: value,
      };
      return {
        ...prev,
        [surahNumber]: updatedInputs,
      };
    });

    // Update verses immediately for Slider
    const numValue = parseInt(value, 10);
    if (!isNaN(numValue)) {
      handleVerseChange(surahNumber, type, numValue, index);
    }
  };

  // Handle verse range changes
  const handleVerseChange = (
    surahNumber: number,
    type: 'startVerse' | 'endVerse',
    value: number,
    index: number
  ) => {
    setVerses((prev) => {
      const currentSurah = surahs.find((s) => s.surahNumber === surahNumber);
      if (!currentSurah) return prev;

      const currentVerses = prev[surahNumber] || [];
      const updatedVerses = [...currentVerses];

      if (!updatedVerses[index]) {
        updatedVerses[index] = { startVerse: null, endVerse: null };
      }

      const boundedValue = Math.max(
        currentSurah.startVerse,
        Math.min(currentSurah.endVerse, value)
      );

      updatedVerses[index] = {
        ...updatedVerses[index],
        [type]: boundedValue,
      };

      if (type === 'startVerse' && updatedVerses[index].endVerse !== null) {
        updatedVerses[index].startVerse = Math.min(
          boundedValue,
          updatedVerses[index].endVerse as number
        );
      } else if (
        type === 'endVerse' &&
        updatedVerses[index].startVerse !== null
      ) {
        updatedVerses[index].endVerse = Math.max(
          boundedValue,
          updatedVerses[index].startVerse as number
        );
      }

      return {
        ...prev,
        [surahNumber]: updatedVerses,
      };
    });

    // Update inputValues to reflect the change
    setInputValues((prev) => {
      const currentInputs = prev[surahNumber] || [];
      const updatedInputs = [...currentInputs];
      if (!updatedInputs[index]) {
        updatedInputs[index] = { startVerse: '', endVerse: '' };
      }
      updatedInputs[index] = {
        ...updatedInputs[index],
        [type]: value.toString(),
      };
      return {
        ...prev,
        [surahNumber]: updatedInputs,
      };
    });
  };

  // Clear verse selection
  const handleClearVerse = (surahNumber: number, index?: number) => {
    if (index !== undefined) {
      setVerses((prev) => {
        const newVerses = { ...prev };
        if (newVerses[surahNumber]) {
          newVerses[surahNumber] = newVerses[surahNumber].filter(
            (_, i) => i !== index
          );
          if (newVerses[surahNumber].length === 0) {
            delete newVerses[surahNumber];
          }
        }
        return newVerses;
      });

      setInputValues((prev) => {
        const newInputValues = { ...prev };
        if (newInputValues[surahNumber]) {
          newInputValues[surahNumber] = newInputValues[surahNumber].filter(
            (_, i) => i !== index
          );
          if (newInputValues[surahNumber].length === 0) {
            delete newInputValues[surahNumber];
          }
        }
        return newInputValues;
      });
    } else {
      setVerses((prev) => {
        const newVerses = { ...prev };
        delete newVerses[surahNumber];
        return newVerses;
      });

      setInputValues((prev) => {
        const newInputValues = { ...prev };
        delete newInputValues[surahNumber];
        return newInputValues;
      });
    }

    setEntireVerse((prev) => {
      const newEntireVerse = [...prev];
      newEntireVerse[surahNumber - 1] = false;
      return newEntireVerse;
    });
  };

  // Handle entire surah selection
  const handleEntireSurahToggle = (
    surahNumber: number,
    checked: boolean,
    surah: Surah
  ) => {
    setEntireVerse((prev) => {
      const newEntireVerse = [...prev];
      newEntireVerse[surahNumber - 1] = checked;
      return newEntireVerse;
    });

    if (checked) {
      setVerses((prev) => ({
        ...prev,
        [surahNumber]: [
          {
            startVerse: surah.startVerse,
            endVerse: surah.endVerse,
          },
        ],
      }));

      setInputValues((prev) => ({
        ...prev,
        [surahNumber]: [
          {
            startVerse: surah.startVerse.toString(),
            endVerse: surah.endVerse.toString(),
          },
        ],
      }));
    } else {
      handleClearVerse(surahNumber);
    }
  };

  const addVerseRange = (surahNumber: number) => {
  const surah = surahs.find(s => s.surahNumber === surahNumber);
  if (!surah) return;

  setVerses((prev) => {
    const currentVerses = prev[surahNumber] || [];
    return {
      ...prev,
      [surahNumber]: [...currentVerses, { startVerse: surah.startVerse, endVerse: surah.endVerse }],
    };
  });

  setInputValues((prev) => {
    const currentInputs = prev[surahNumber] || [];
    return {
      ...prev,
      [surahNumber]: [...currentInputs, { startVerse: surah.startVerse.toString(), endVerse: surah.endVerse.toString() }],
    };
  });
};

  // Navigate to review page
  const handleConfirmLesson = () => {
    const selectedData = {
      verses,
      mode: AssignmentMutationMode.create,
    };

    navigate(`/student/${state.user?.id}/review-assignment`, {
      state: {
        assignment: {
          studentId: state.user.id,
          type: state.type,
        },
        ...selectedData,
        user: state.user,
        mode: AssignmentMutationMode.create,
      },
      replace: true,
    });
  };

  return (
    <>
      <HeaderNav title={formattedTitle} />
      <Card className="w-full pb-24 mx-auto border-none shadow-none md:w-4/5 xl:w-3/5">
        <CardContent className="p-4">
          <div className="space-y-6">
            {/* Search Input */}
            <CardHeader className="px-0">
              <p>Select a surah and the required verses</p>
              <div className="my-4">
                <Input
                  type="text"
                  placeholder="Search Surahs"
                  onChange={filterItems}
                  icon={<Search className="w-4 h-4 opacity-50" />}
                />
              </div>
            </CardHeader>

            {/* Selected Surahs Summary */}
            {Object.keys(verses).length > 0 && (
              <div className="mb-6">
                <div className="mb-3">
                  <h3 className="text-sm font-medium text-gray-700">
                    Selected Surahs
                  </h3>
                </div>
                <div className="flex flex-wrap gap-2">
                  {Object.entries(verses).map(([surahNumber, ranges]) => {
                    const surah = surahs.find(
                      (s) => s.surahNumber === parseInt(surahNumber)
                    );
                    if (!surah) return null;
                    return (
                      <Badge
                        key={surahNumber}
                        variant="default"
                        className="rounded-full px-3 py-1.5 flex items-center gap-2 group transition-colors bg-primary/10 hover:bg-primary/20 text-primary border-transparent"
                      >
                        <span className="flex items-center gap-2">
                          <span className="font-medium">{surah.name}</span>
                          <span className="text-xs opacity-80">
                            {ranges.length === 1 ? (
                              `Verses ${ranges[0].startVerse}-${ranges[0].endVerse}`
                            ) : (
                              `${ranges.length} ranges`
                            )}
                          </span>
                        </span>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleClearVerse(parseInt(surahNumber));
                          }}
                          className="opacity-50 group-hover:opacity-100 transition-opacity"
                        >
                          <X className="h-3 w-3" />
                        </button>
                      </Badge>
                    );
                  })}
                </div>
              </div>
            )}

            {/* Surah Selector */}
            <Accordion type="multiple" className="space-y-3">
              {filteredSurahs.map((surah: Surah) => (
                <AccordionItem
                  key={surah.surahNumber}
                  value={`${surah.surahNumber}`}
                  className={`bg-white rounded-3xl overflow-hidden transition-all ${
                    verses[surah.surahNumber]?.[0]?.startVerse &&
                    verses[surah.surahNumber]?.[0]?.endVerse
                      ? 'border-2 border-gray-100'
                      : 'border-2 border-gray-100'
                  }`}
                >
                  <AccordionTrigger className="px-4 py-4 sm:px-6 hover:no-underline [&[data-state=open]]:bg-gray-50/50">
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center gap-4">
                        <div
                          className={`w-11 h-11 rounded-xl flex items-center justify-center text-sm font-medium transition-colors ${
                            verses[surah.surahNumber]?.[0]?.startVerse &&
                            verses[surah.surahNumber]?.[0]?.endVerse
                              ? 'bg-primary/10 text-primary'
                              : 'bg-gray-100 text-gray-800'
                          }`}
                        >
                          {surah.surahNumber}
                        </div>
                        <div className="flex flex-col items-start">
                          <h3 className="font-medium text-gray-900">
                            {surah.name}
                          </h3>
                          <p className="text-sm text-gray-500">
                            {verses[surah.surahNumber]?.length > 0
                              ? verses[surah.surahNumber].length === 1
                                ? `Verses ${verses[surah.surahNumber][0].startVerse}-${verses[surah.surahNumber][0].endVerse}`
                                : `${verses[surah.surahNumber].length} ranges`
                              : 'No verses selected'}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <div className="text-xs px-3 py-1 rounded-full bg-gray-100 text-gray-500">
                          {surah.endVerse - surah.startVerse + 1} verses
                        </div>
                        <ChevronDown className="h-4 w-4 shrink-0 text-gray-500 transition-transform duration-200" />
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="px-6 pt-4 pb-6">
                    <div className="space-y-6">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-4">
                          <Switch
                            id={`entire-verse-${surah.surahNumber}`}
                            checked={
                              entireVerse[surah.surahNumber - 1] || false
                            }
                            onCheckedChange={(checked) =>
                              handleEntireSurahToggle(
                                surah.surahNumber,
                                checked,
                                surah
                              )
                            }
                          />
                          <Label
                            htmlFor={`entire-verse-${surah.surahNumber}`}
                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                          >
                            Entire Surah
                          </Label>
                        </div>
                        {!entireVerse[surah.surahNumber - 1] && (
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => addVerseRange(surah.surahNumber)}
                            className="rounded-full"
                          >
                            <Plus className="h-4 w-4 mr-2" />
                            Add Range
                          </Button>
                        )}
                      </div>

                      {!entireVerse[surah.surahNumber - 1] && (
                        <div className="space-y-4">
                          {(inputValues[surah.surahNumber] || []).map(
                            (range, index) => (
                              <Card
                                key={index}
                                className="p-6 bg-gray-100/70 rounded-2xl border-none"
                              >
                                <div className="flex justify-between items-center mb-4">
                                  <span className="text-sm font-medium text-gray-700">
                                    Range {index + 1}
                                  </span>
                                  <Button
                                    variant="ghost"
                                    size="sm"
                                    onClick={() =>
                                      handleClearVerse(surah.surahNumber, index)
                                    }
                                    className="h-8 w-8 p-0 rounded-full"
                                  >
                                    <Minus className="h-4 w-4" />
                                    <span className="sr-only">
                                      Remove range
                                    </span>
                                  </Button>
                                </div>
                                <div className="space-y-4">
                                  <div className="flex justify-between items-center">
                                    <Label
                                      htmlFor={`range-${surah.surahNumber}-${index}`}
                                      className="text-sm font-medium text-gray-700"
                                    >
                                      Verse Range
                                    </Label>
                                    <span className="text-sm font-semibold text-gray-900">
                                      {range.startVerse || surah.startVerse} -{' '}
                                      {range.endVerse || surah.endVerse}
                                    </span>
                                  </div>
                                  <SliderPrimitive.Root
                                    id={`range-${surah.surahNumber}-${index}`}
                                    className="relative flex items-center select-none touch-none w-full h-5"
                                    min={surah.startVerse}
                                    max={surah.endVerse}
                                    step={1}
                                    value={[
                                      parseInt(range.startVerse.toString()) ||
                                        surah.startVerse,
                                      parseInt(range.endVerse.toString()) ||
                                        surah.endVerse,
                                    ]}
                                    onValueChange={(value) => {
                                      handleInputChange(
                                        surah.surahNumber,
                                        'startVerse',
                                        value[0].toString(),
                                        index
                                      );
                                      handleInputChange(
                                        surah.surahNumber,
                                        'endVerse',
                                        value[1].toString(),
                                        index
                                      );
                                    }}
                                  >
                                    <SliderPrimitive.Track className="bg-gray-200 relative grow rounded-full h-2">
                                      <SliderPrimitive.Range className="absolute bg-teal-700 rounded-full h-full" />
                                    </SliderPrimitive.Track>
                                    {[0, 1].map((thumbIndex) => (
                                      <SliderPrimitive.Thumb
                                        key={thumbIndex}
                                        className="block w-5 h-5 bg-white border-2 border-teal-700 rounded-full shadow focus:outline-none hover:scale-110 transition-all duration-150 ease-out will-change-transform"
                                      />
                                    ))}
                                  </SliderPrimitive.Root>
                                </div>
                              </Card>
                            )
                          )}
                        </div>
                      )}

                      {errors[surah.surahNumber] && (
                        <p className="text-sm text-red-500 mt-2">
                          {errors[surah.surahNumber]}
                        </p>
                      )}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </CardContent>
      </Card>
      <div className="bg-white fixed bottom-0 w-full py-3 px-4 border-t border-[#C5C5C5] flex justify-end">
        <Button
          variant="ghost"
          onClick={() => navigate(-1)}
          className="mr-2 rounded-xl font-bold text-primary"
        >
          Cancel
        </Button>
        <Button
          variant="default"
          onClick={handleConfirmLesson}
          className="rounded-xl font-bold"
          disabled={Object.keys(verses).length === 0}
        >
          Review Lesson
        </Button>
      </div>
    </>
  );
}

