import React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import { Button } from './ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import { Label } from './ui/label';
import { Switch } from './ui/switch';
import { ScrollArea } from './ui/scroll-area';
import { surahNameMap } from '../utils/surahUtils';
import { Section } from '../types/common';
import { cn } from '../lib/utils';

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex items-center select-none touch-none w-full h-5',
      className
    )}
    {...props}
  >
    <SliderPrimitive.Track className="bg-gray-200 relative grow rounded-full h-2">
      <SliderPrimitive.Range className="absolute bg-teal-700 rounded-full h-full" />
    </SliderPrimitive.Track>
    {[0, 1].map((thumbIndex) => (
      <SliderPrimitive.Thumb
        key={thumbIndex}
        className="block w-5 h-5 bg-white border-2 border-teal-700 rounded-full shadow focus:outline-none hover:scale-110 transition-all duration-150 ease-out will-change-transform"
      />
    ))}
  </SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

interface StatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  sections: Array<Section & { id: number }>;
  selectedSections: {
    [key: number]: { startVerse: number; endVerse: number; selected: boolean };
  };
  onSectionToggle: (surahNumber: number) => void;
  setSelectedSections: (prev: any) => any;
}

const PartialPassSelector = ({
  isOpen,
  onClose,
  onConfirm,
  sections,
  selectedSections,
  onSectionToggle,
  setSelectedSections,
}: StatusModalProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px] bg-white p-6">
        <DialogHeader className="pb-6">
          <DialogTitle className="text-xl font-medium text-gray-900">
            Select a Section for Partial Pass
          </DialogTitle>
        </DialogHeader>

        <ScrollArea className="max-h-[400px] -mr-6 pr-6">
          <div className="space-y-3">
            {sections.map((section) => {
              const isSelected = selectedSections[section.id].selected;
              return (
                <div key={section.id} className="relative">
                  <div
                    className={`
                      group relative border-2 rounded-3xl bg-white p-5 sm:p-6
                      transition-all duration-200
                      ${
                        isSelected
                          ? 'border-gray-100 bg-gray-50'
                          : 'border-gray-100'
                      }
                    `}
                  >
                    <div className="flex items-start justify-between">
                      <div className="flex gap-3">
                        <div
                          className={`
                          w-12 h-12 rounded-2xl flex items-center justify-center
                          ${isSelected ? 'bg-teal-600/10' : 'bg-gray-100'}
                        `}
                        >
                          <span className="text-base font-semibold text-gray-800">
                            {section.surahNumber}
                          </span>
                        </div>
                        <div>
                          <h3 className="font-medium text-gray-900">
                            {surahNameMap[section.surahNumber]}
                          </h3>
                          <p className="text-sm text-gray-500 mt-0.5">
                            {section.startVerse === section.endVerse
                              ? `Verse ${section.startVerse}`
                              : `Verses ${section.startVerse}-${section.endVerse}`}
                          </p>
                        </div>
                      </div>
                      <Switch
                        checked={isSelected}
                        onCheckedChange={() => onSectionToggle(section.id)}
                        className="data-[state=checked]:bg-teal-600"
                      />
                    </div>

                    {isSelected && (
                      <div className="mt-4 pt-4 border-t">
                        <div className="space-y-4">
                          <div className="flex justify-between items-center">
                            <Label
                              htmlFor={`range-${section.id}`}
                              className="text-sm font-medium text-gray-700"
                            >
                              Verse Range
                            </Label>
                            <span className="text-sm font-semibold text-gray-900">
                              {selectedSections[section.id].startVerse ===
                              selectedSections[section.id].endVerse
                                ? `${selectedSections[section.id].startVerse}`
                                : `${selectedSections[section.id].startVerse} - ${selectedSections[section.id].endVerse}`}
                            </span>
                          </div>
                          <Slider
                            id={`range-${section.id}`}
                            min={section.startVerse}
                            max={section.endVerse}
                            step={1}
                            value={[
                              selectedSections[section.id].startVerse,
                              selectedSections[section.id].endVerse,
                            ]}
                            onValueChange={(value) => {
                              setSelectedSections((prev: any) => ({
                                ...prev,
                                [section.id]: {
                                  ...prev[section.id],
                                  startVerse: value[0],
                                  endVerse: value[1],
                                },
                              }));
                            }}
                            className="mt-2"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </ScrollArea>

        <div className="flex justify-end gap-2 pt-6 mt-6 border-t">
          <Button
            variant="outline"
            onClick={onClose}
            className="text-gray-700 rounded-xl"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            className="bg-teal-600 hover:bg-teal-700 text-white min-w-[80px] rounded-xl"
          >
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PartialPassSelector;
