'use client';

import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import {
  ArrowLeft,
  Calendar as CalendarIcon,
  BookText,
  Menu as MenuIcon,
  Users,
  LogOut,
  BookOpen,
} from 'lucide-react';
import { cn } from '../lib/utils';
import { Button } from './ui/button';
import { Calendar } from './ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { Sheet, SheetContent, SheetTrigger } from './ui/sheet';
import { Link } from 'react-router-dom';

const navigation = [
  { name: 'Students', href: '/students', icon: Users },
  { name: 'Resources', href: '/resources', icon: BookText },
];

export default function Component({
  title = 'Hifz Tracker',
  showDate = false,
  date,
  onDateChange,
  onBack,
}: {
  title: string | React.ReactElement;
  showDate?: boolean;
  date?: Date;
  onDateChange?: (date: Date | undefined) => void;
  onBack?: () => void;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const shouldShowBackArrow = ![
    '/students',
    '/create-assignment',
    '/dashboard',
  ].includes(location.pathname);

  const handleDateChange = (newDate: Date | undefined) => {
    if (newDate) {
      const adjustedDate = new Date(
        Date.UTC(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          12,
          0,
          0
        )
      );
      onDateChange?.(adjustedDate);
      setIsCalendarOpen(false); // Close the calendar when a date is selected
    } else {
      onDateChange?.(undefined);
    }
  };

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <header className="relative z-50">
      {/* Modern gradient background with frosted glass effect */}
      <div className="absolute inset-0 bg-gradient-to-br from-teal-600 to-teal-700">
        <div className="absolute inset-0 mix-blend-overlay opacity-10">
          <div className="absolute inset-0 bg-[radial-gradient(#fff_1px,transparent_1px)] [background-size:16px_16px]"></div>
        </div>
        <div className="absolute inset-0 backdrop-blur-[2px] bg-gradient-to-b from-black/10 to-transparent"></div>
      </div>

      <div className="relative mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Left Section: Back Button, Logo, and Title */}
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2 md:gap-4">
              {shouldShowBackArrow && (
                <button
                  onClick={handleBack}
                  className="rounded-full p-2 hover:bg-white/10 transition-colors"
                >
                  <ArrowLeft className="h-6 w-6 text-white" />
                </button>
              )}

              <div className="flex items-center gap-3">
                <div className="relative group hidden md:block">
                  <div className="absolute -inset-0.5 rounded-xl bg-gradient-to-br from-white/30 to-white/10 blur-sm transition-all duration-200 group-hover:from-white/40 group-hover:blur-md"></div>
                  <div className="relative p-3 rounded-2xl bg-gradient-to-br from-white/20 to-white/5 backdrop-blur-sm border border-white/20 transition-all duration-200 group-hover:border-white/30 group-hover:scale-105">
                    <BookOpen className="h-6 w-6 text-white" strokeWidth={2} />
                  </div>
                </div>
                <div>
                  <h1 className="text-white font-bold text-lg md:text-xl tracking-tight">
                    {title}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          {/* Right Section: Date Picker and Navigation */}
          <div className="flex items-center gap-3 md:gap-4">
            {showDate && (
              <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
                <PopoverTrigger asChild>
                  <Button
                    variant="ghost"
                    className={cn(
                      'relative group flex h-11 items-center justify-center rounded-xl text-white hover:text-white hover:bg-white/10 transition-all duration-200',
                      !date && 'text-white'
                    )}
                  >
                    <CalendarIcon className="h-5 w-5 opacity-80 group-hover:opacity-100" />
                    {date ? (
                      <span className="ml-2 text-sm">
                        {format(date, 'MMM dd, yyyy')}
                      </span>
                    ) : (
                      <span className="ml-2 text-sm opacity-80 group-hover:opacity-100">
                        Pick a date
                      </span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="end">
                  <Calendar
                    mode="single"
                    selected={date}
                    onSelect={handleDateChange}
                    disabled={(date) =>
                      date > new Date() || date < new Date('1900-01-01')
                    }
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            )}

            {/* Mobile Navigation */}
            <Sheet>
              <SheetTrigger asChild>
                <Button
                  variant="ghost"
                  className="relative group flex h-11 w-11 items-center justify-center rounded-full text-white hover:bg-white/20 hover:scale-105 transition-all duration-200 md:hidden"
                >
                  <MenuIcon className="h-5 w-5 flex-shrink-0" />
                  <span className="sr-only">Open menu</span>
                </Button>
              </SheetTrigger>
              <SheetContent
                side="right"
                className="w-64 bg-gradient-to-br from-teal-600 to-teal-700 border-none"
              >
                <nav className="mt-6">
                  <ul className="space-y-2">
                    {navigation.map((item) => {
                      const Icon = item.icon;
                      return (
                        <li key={item.name}>
                          <Link
                            to={item.href}
                            className="flex items-center gap-3 px-4 py-3 text-white/90 hover:text-white rounded-xl hover:bg-white/10 transition-all duration-200"
                          >
                            <Icon className="h-5 w-5" />
                            <span className="font-medium">{item.name}</span>
                          </Link>
                        </li>
                      );
                    })}
                    <li>
                      <button
                        onClick={() => {
                          navigate('/login');
                        }}
                        className="w-full flex items-center gap-3 px-4 py-3 text-white/90 hover:text-white rounded-xl hover:bg-white/10 transition-all duration-200"
                      >
                        <LogOut className="h-5 w-5" />
                        <span className="font-medium">Logout</span>
                      </button>
                    </li>
                  </ul>
                </nav>
              </SheetContent>
            </Sheet>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex items-center gap-2">
              {navigation.map((item) => {
                const Icon = item.icon;
                const isActive = location.pathname === item.href;
                return (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={cn(
                      'relative group flex items-center gap-2 px-4 py-2 rounded-xl text-white/90 hover:text-white transition-all duration-200',
                      isActive ? 'bg-white/15' : 'hover:bg-white/10'
                    )}
                  >
                    <Icon className="h-5 w-5" />
                    <span className="font-medium">{item.name}</span>
                  </Link>
                );
              })}
              <Button
                variant="ghost"
                onClick={() => {
                  navigate('/login');
                }}
                className="relative group flex items-center gap-2 px-4 py-2 rounded-xl text-white/90 hover:text-white hover:bg-white/10 transition-all duration-200"
              >
                <LogOut className="h-5 w-5" />
                <span className="font-medium">Logout</span>
              </Button>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}
