import { Link, useLocation, matchPath } from 'react-router-dom';
import { BarChart2, NotepadText } from 'lucide-react';
import { pathHash } from '../lib/utils';
import { useAuth } from '../contexts/AuthContext';

const GeneralFooter = ({ pathHash }: { pathHash: string }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const { isAtLeastTeacher, user } = useAuth();

  const match = matchPath({ path: '/student/:id/*' }, pathname);
  const studentId = isAtLeastTeacher ? match?.params?.id : user?.id;

  return (
    <footer className="fixed bottom-0 w-full z-50">
      {/* Gradient border top */}

      {/* Frosted glass effect */}
      <div className="relative bg-white border-t-2 border-gray-100">
        <nav className="max-w-7xl mx-auto px-8 py-6">
          <div className="flex items-center justify-between gap-8">
            <div className="flex-1 flex justify-center">
              {studentId && (
                <Link
                  to={`/student/${studentId}`}
                  replace={true}
                  className={`relative group flex flex-col items-center min-w-[64px] transition-all duration-200 ${
                    pathHash === 'student'
                      ? 'text-teal-600'
                      : 'text-gray-400 hover:text-teal-600'
                  }`}
                >
                  <div className="relative">
                    <div
                      className={`absolute inset-0 rounded-xl ${
                        pathHash === 'student'
                          ? 'bg-teal-100 animate-ping-slow opacity-70'
                          : 'bg-transparent group-hover:bg-teal-50'
                      } -m-2 transition-colors duration-200`}
                    />
                    <NotepadText
                      className={`h-6 w-6 transition-transform duration-200 ${
                        pathHash === 'student'
                          ? 'scale-110'
                          : 'group-hover:scale-110'
                      }`}
                    />
                  </div>
                  <div
                    className={`text-xs font-medium mt-2 transition-colors duration-200 ${
                      pathHash === 'student'
                        ? 'text-teal-900'
                        : 'text-gray-600 group-hover:text-teal-900'
                    }`}
                  >
                    Assignments
                  </div>
                </Link>
              )}
            </div>

            <div className="flex-1 flex justify-center">
              {studentId && (
                <Link
                  to={`/student/${studentId}/analytics`}
                  replace={true}
                  className={`relative group flex flex-col items-center min-w-[64px] transition-all duration-200 ${
                    pathHash === 'analytics'
                      ? 'text-teal-600'
                      : 'text-gray-400 hover:text-teal-600'
                  }`}
                >
                  <div className="relative">
                    <div
                      className={`absolute inset-0 rounded-xl ${
                        pathHash === 'analytics'
                          ? 'bg-teal-100 animate-ping-slow opacity-70'
                          : 'bg-transparent group-hover:bg-teal-50'
                      } -m-2 transition-colors duration-200`}
                    />
                    <BarChart2 className="h-6 w-6 transition-transform duration-200 group-hover:scale-110" />
                  </div>
                  <span className="mt-2 text-xs font-medium">Analytics</span>
                </Link>
              )}
            </div>
          </div>
        </nav>
      </div>
    </footer>
  );
};

export default function FooterNav() {
  const location = useLocation();
  const pathname = location.pathname;
  const path = pathHash(pathname);

  const footerPaths = ['student', 'dashboard', 'student/:id/analytics'];

  if (!footerPaths.includes(path)) {
    return null;
  }

  return <GeneralFooter pathHash={path} />;
}
