'use client';

import { useState, useEffect } from 'react';
import { Button } from './ui/button';
import { Input } from './ui/input';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';
import { useAuth } from '../contexts/AuthContext';
import {
  FolderSearch,
  Plus,
  Trash2,
  Youtube,
  Mic,
  BookOpen,
  Brain,
} from 'lucide-react';
import HeaderNav from './HeaderNav';

interface Resource {
  id: string;
  title: string;
  url: string;
  type: 'recitation' | 'tajweed' | 'memorization';
  dateAdded: string;
}

type ResourceType = Resource['type'] | 'all';

export default function ImprovedResourcesPage() {
  const [resources, setResources] = useState<Resource[]>([]);
  const [newResourceUrl, setNewResourceUrl] = useState('');
  const [newResourceTitle, setNewResourceTitle] = useState('');
  const [newResourceType, setNewResourceType] =
    useState<Resource['type']>('recitation');
  const [isAddingResource, setIsAddingResource] = useState(false);
  const [selectedType, setSelectedType] = useState<ResourceType>('all');
  const { isAtLeastTeacher } = useAuth();

  const resourceTypes = [
    { value: 'all' as const, label: 'All Resources', icon: FolderSearch },
    { value: 'recitation' as const, label: 'Recitation', icon: Mic },
    { value: 'tajweed' as const, label: 'Tajweed Rules', icon: BookOpen },
    { value: 'memorization' as const, label: 'Memorization', icon: Brain },
  ];

  useEffect(() => {
    // Fetch resources from your API here
    setResources([
      {
        id: '1',
        title: 'Complete Quran Recitation',
        url: 'https://www.youtube.com/embed/Nnd641CP1k8',
        type: 'recitation',
        dateAdded: new Date().toISOString(),
      },
      {
        id: '2',
        title: 'Essential Tajweed Rules Explained',
        url: 'https://www.youtube.com/embed/Nnd641CP1k8',
        type: 'tajweed',
        dateAdded: new Date(Date.now() - 86400000).toISOString(),
      },
      {
        id: '3',
        title: 'Advanced Memorization Techniques',
        url: 'https://www.youtube.com/embed/Nnd641CP1k8',
        type: 'memorization',
        dateAdded: new Date(Date.now() - 172800000).toISOString(),
      },
    ]);
  }, []);

  const addResource = () => {
    if (newResourceUrl && newResourceTitle) {
      const videoId = extractVideoId(newResourceUrl);
      if (videoId) {
        const newResource = {
          id: Date.now().toString(),
          title: newResourceTitle,
          url: `https://www.youtube.com/embed/${videoId}`,
          type: newResourceType,
          dateAdded: new Date().toISOString(),
        };
        setResources([...resources, newResource]);
        setNewResourceUrl('');
        setNewResourceTitle('');
        setIsAddingResource(false);
      } else {
        alert('Invalid YouTube URL');
      }
    }
  };

  const removeResource = (id: string) => {
    setResources(resources.filter((resource) => resource.id !== id));
  };

  const extractVideoId = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=)([^#]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const filteredResources = resources.filter(
    (resource) => selectedType === 'all' || resource.type === selectedType
  );

  const formatDate = (date: string) => {
    const d = new Date(date);
    return d.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
    });
  };

  return (
    <div className="min-h-screen bg-white">
      <HeaderNav title={<span className="font-bold">Resources</span>} />

      <div className="container mx-auto px-4 py-8">
        {/* Header Section */}
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">
            Learning Resources
          </h1>
          <p className="text-gray-600">
            Access curated video resources to enhance your Quran learning
            journey
          </p>
        </div>

        {/* Filter and Add Resource Section */}
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-8">
          <Select
            value={selectedType}
            onValueChange={(value) => setSelectedType(value as ResourceType)}
          >
            <SelectTrigger className="sm:w-[200px]">
              <SelectValue placeholder="Select type" />
            </SelectTrigger>
            <SelectContent>
              {resourceTypes.map(({ value, label, icon: Icon }) => (
                <SelectItem key={value} value={value}>
                  <div className="flex items-center gap-2">
                    <Icon className="h-4 w-4" />
                    <span>{label}</span>
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          {isAtLeastTeacher && (
            <Dialog open={isAddingResource} onOpenChange={setIsAddingResource}>
              <DialogTrigger asChild>
                <Button className="w-full sm:w-auto flex items-center gap-2 p-6 rounded-xl">
                  <Plus className="h-4 w-4" strokeWidth={2.5} />
                  Add Resource
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Add New Resource</DialogTitle>
                  <DialogDescription>
                    Add a new YouTube video resource for students
                  </DialogDescription>
                </DialogHeader>

                <div className="grid gap-4 py-4">
                  <div className="grid gap-2">
                    <label htmlFor="type">Resource Type</label>
                    <Select
                      value={newResourceType}
                      onValueChange={(value: Resource['type']) =>
                        setNewResourceType(value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select type" />
                      </SelectTrigger>
                      <SelectContent>
                        {resourceTypes
                          .slice(1)
                          .map(({ value, label, icon: Icon }) => (
                            <SelectItem key={value} value={value}>
                              <div className="flex items-center gap-2">
                                <Icon className="h-4 w-4" />
                                <span>{label}</span>
                              </div>
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="grid gap-2">
                    <label htmlFor="title">Title</label>
                    <Input
                      id="title"
                      value={newResourceTitle}
                      onChange={(e) => setNewResourceTitle(e.target.value)}
                      placeholder="Enter resource title"
                    />
                  </div>

                  <div className="grid gap-2">
                    <label htmlFor="url">YouTube URL</label>
                    <Input
                      id="url"
                      value={newResourceUrl}
                      onChange={(e) => setNewResourceUrl(e.target.value)}
                      placeholder="Enter YouTube URL"
                    />
                  </div>
                </div>

                <DialogFooter>
                  <Button onClick={addResource}>Add Resource</Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          )}
        </div>

        {/* Resources Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredResources.map((resource) => (
            <div
              key={resource.id}
              className="group bg-white rounded-3xl shadow-lg hover:shadow-md transition-all duration-300 overflow-hidden"
            >
              <div className="relative aspect-video">
                <iframe
                  width="100%"
                  height="100%"
                  src={resource.url}
                  title={resource.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full object-cover"
                ></iframe>
              </div>

              <div className="p-4">
                <div className="flex items-start justify-between gap-4 mb-2">
                  <h3 className="font-medium text-gray-900 line-clamp-2">
                    {resource.title}
                  </h3>

                  {isAtLeastTeacher && (
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => removeResource(resource.id)}
                      className="h-8 w-8 text-gray-500 hover:text-red-600"
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  )}
                </div>

                <div className="flex items-center gap-2 text-sm">
                  {(() => {
                    const { icon: TypeIcon } = resourceTypes.find(
                      (t) => t.value === resource.type
                    ) || { icon: FolderSearch };
                    return (
                      <span className="inline-flex items-center gap-1.5 px-2 py-1 rounded-md bg-gray-100 text-gray-700 text-xs">
                        <TypeIcon className="h-3.5 w-3.5" />
                        {resource.type.charAt(0).toUpperCase() +
                          resource.type.slice(1)}
                      </span>
                    );
                  })()}
                  <span className="text-gray-400">•</span>
                  <span className="text-gray-500 text-xs">
                    {formatDate(resource.dateAdded)}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Empty State */}
        {filteredResources.length === 0 && (
          <div className="text-center py-16">
            <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-4">
              <Youtube className="h-6 w-6 text-gray-600" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-1">
              No resources found
            </h3>
            <p className="text-sm text-gray-500">
              {isAtLeastTeacher
                ? 'Start by adding your first video resource'
                : 'Check back later for new learning resources'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
