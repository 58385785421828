'use client';

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  CardTitle,
} from './ui/card';
import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { Badge } from './ui/badge';
import { Skeleton } from './ui/skeleton';
import { Tabs, TabsList, TabsTrigger } from './ui/tabs';
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  LabelList,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import {
  Book,
  Clock,
  Bookmark,
  RefreshCw,
  CircleCheck,
  CircleAlert,
  CircleX,
  CircleEllipsis,
  CirclePause,
  Calendar,
  Pencil,
} from 'lucide-react';
import { format, startOfMonth, startOfYear } from 'date-fns';

import HeaderNav from './HeaderNav';
import { useGetUser } from '../hooks/useGetUser';
import { useGetAssignments } from '../hooks/useGetAssignments';
import { useGetAggregateAttendance } from '../hooks/useGetAggregateAttendance';
import { useAuth } from '../contexts/AuthContext';
import { surahNameMap } from '../utils/surahUtils';
import {
  AssignmentType,
  Assignment as CommonAssignment,
  AttendanceAggregate,
  Section,
  AssignmentMutationMode,
} from '../types/common';
import { AxiosError } from 'axios';
import { BackendError } from '../types/error';
import api from '../services/axios';
import { toast } from '../hooks/useToast';
import PartialPassSelector from './PartialPassSelector';
import { ScrollArea } from './ui/scroll-area';

const sortVerseRanges = (ranges: any[]) => {
  return [...ranges].sort((a, b) => {
    if (a.surahNumber !== b.surahNumber) {
      return a.surahNumber - b.surahNumber;
    }
    return a.startVerse - b.startVerse;
  });
};

interface Assignment
  extends Omit<CommonAssignment, 'id' | 'Student' | 'studentId'> {
  id: string;
}

interface AssignmentProps {
  assignment: Assignment;
}

export default function Component() {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { isAtLeastTeacher } = useAuth();
  const { user, loading: userLoading } = useGetUser(id);
  const {
    assignments,
    loading: assignmentsLoading,
    refetch,
  } = useGetAssignments(id);
  const [assignmentResult, setAssignmentResult] = useState<{
    [key: string]: string;
  }>({});
  const [isStatusModalOpen, setIsStatusModalOpen] = React.useState(false);
  const [selectedSections, setSelectedSections] = React.useState<{
    [key: number]: {
      startVerse: number;
      endVerse: number;
      selected: boolean;
    };
  }>({});
  const [pendingAssignment, setPendingAssignment] =
    React.useState<Assignment | null>(null);
  const [currentTab, setCurrentTab] = useState('monthly');
  const [attendanceSummary, setAttendanceSummary] =
    useState<AttendanceAggregate>({ present: 0, absent: 0, late: 0 });

  const { monthlyAttendanceSummary, yearlyAttendanceSummary } =
    useGetAggregateAttendance(id);

  useEffect(() => {
    const summary =
      currentTab === 'monthly'
        ? monthlyAttendanceSummary
        : yearlyAttendanceSummary;
    if (summary) {
      setAttendanceSummary({
        present: summary.present || 0,
        absent: summary.absent || 0,
        late: summary.late || 0,
      });
    }
  }, [currentTab, monthlyAttendanceSummary, yearlyAttendanceSummary]);

  const assignmentTypeOrder = [
    AssignmentType.new,
    AssignmentType.past,
    AssignmentType.seven_pages,
    AssignmentType.revision,
  ];

  function handleStatusChange(assignment: Assignment, value: string) {
    if (value === 'partial_pass') {
      setPendingAssignment(assignment);
      const initialSections = assignment.Sections?.reduce(
        (
          acc: {
            [key: number]: {
              startVerse: number;
              endVerse: number;
              selected: boolean;
            };
          },
          section: Section
        ) => {
          acc[section.id ?? 0] = {
            startVerse: section.startVerse,
            endVerse: section.endVerse,
            selected: false,
          };
          return acc;
        },
        {} as {
          [key: number]: {
            startVerse: number;
            endVerse: number;
            selected: boolean;
          };
        }
      );
      setSelectedSections(initialSections || {});
      setIsStatusModalOpen(true);
    } else {
      setAssignmentResult((prev) => ({
        ...prev,
        [assignment.id]: value,
      }));
      api
        .put(`/api/assignment/${assignment.id}/status`, {
          status: value,
        })
        .then(() => {
          refetch();
        })
        .catch((error: AxiosError<BackendError>) => {
          const title = error?.response?.data?.title ?? 'An Error has occurred';
          const description =
            error?.response?.data?.message ?? 'Lesson could not be created';
          toast({
            title,
            description,
            icon: (
              <CircleAlert
                className="text-white dark:text-slate-900"
                fill="red"
              />
            ),
          });
        });
    }
  }

  const handleSectionToggle = (sectionId: number) => {
    setSelectedSections((prev) => ({
      ...prev,
      [sectionId]: { ...prev[sectionId], selected: !prev[sectionId].selected },
    }));
  };
  const handleStatusModalConfirm = () => {
    if (pendingAssignment) {
      console.log({
        selections: Object.keys(selectedSections)
          .filter((e: string) => selectedSections[+e].selected)
          .map((e) => selectedSections[+e]),
      });
      // api
      //   .put(`/api/assignment/${pendingAssignment.id}/status`, {
      //     status: 'partial_pass',
      //     sectionResults,
      //   })
      //   .then(() => {
      //     refetch();
      //     setAssignmentResult((prev) => ({
      //       ...prev,
      //       [pendingAssignment.id]: 'partial_pass',
      //     }));
      //   })
      //   .catch((error: AxiosError<BackendError>) => {
      //     const title = error?.response?.data?.title ?? 'An Error has occurred';
      //     const description =
      //       error?.response?.data?.message ?? 'Status could not be updated';
      //     toast({
      //       title,
      //       description,
      //       icon: (
      //         <CircleAlert
      //           className="text-white dark:text-slate-900"
      //           fill="red"
      //         />
      //       ),
      //     });
      //   })
      //   .finally(() => {
      //     setIsStatusModalOpen(false);
      //     setPendingAssignment(null);
      //     setSelectedSections({});
      //   });
    }
  };

  const DropDown = ({
    assignment,
    value,
  }: {
    assignment: Assignment;
    value: string;
  }) => {
    const currentStatus = assignmentResult[assignment.id] ?? value;
    const getStatusConfig = (status: string) => {
      const configs = {
        draft: {
          icon: CircleEllipsis,
          color: 'text-gray-600',
          bg: 'bg-gray-50',
          border: 'border-transparent',
        },
        pass: {
          icon: CircleCheck,
          color: 'text-green-600',
          bg: 'bg-green-50',
          border: 'border-transparent',
        },
        partial_pass: {
          icon: CircleAlert,
          color: 'text-yellow-600',
          bg: 'bg-yellow-50',
          border: 'border-transparent',
        },
        fail: {
          icon: CircleX,
          color: 'text-red-600',
          bg: 'bg-red-50',
          border: 'border-transparent',
        },
        in_progress: {
          icon: CircleEllipsis,
          color: 'text-blue-600',
          bg: 'bg-blue-50',
          border: 'border-transparent',
        },
        pause: {
          icon: CirclePause,
          color: 'text-orange-600',
          bg: 'bg-orange-50',
          border: 'border-transparent',
        },
      };
      return configs[status as keyof typeof configs] ?? configs.draft;
    };

    const currentConfig = getStatusConfig(currentStatus);
    const Icon = currentConfig.icon;

    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            className={`w-11 h-11 p-0 flex items-center justify-center rounded-full border-2 hover:bg-white shadow-md ${currentConfig.bg} ${currentConfig.border}`}
          >
            <Icon className={`h-5 w-5 ${currentConfig.color}`} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-48 p-2 rounded-2xl">
          {[
            { value: 'draft', label: 'Not Started' },
            { value: 'pass', label: 'Pass' },
            { value: 'partial_pass', label: 'Partial Pass' },
            { value: 'fail', label: 'Fail' },
            { value: 'in_progress', label: 'In Progress' },
            { value: 'pause', label: 'Pause' },
          ].map(({ value: statusValue, label }) => {
            const config = getStatusConfig(statusValue);
            const StatusIcon = config.icon;
            return (
              <DropdownMenuItem
                key={statusValue}
                className={`flex items-center gap-2 cursor-pointer rounded-xl focus:bg-gray-50  ${
                  currentStatus === statusValue && config.bg
                }`}
                onClick={() => handleStatusChange(assignment, statusValue)}
              >
                <StatusIcon className={`h-4 w-4 ${config.color}`} />
                <span className={config.color}>{label}</span>
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  };

  const getAssignmentTypeIcon = (type: AssignmentType) => {
    const icons = {
      [AssignmentType.new]: Book,
      [AssignmentType.past]: Clock,
      [AssignmentType.seven_pages]: Bookmark,
      [AssignmentType.revision]: RefreshCw,
    };
    const Icon = icons[type];
    return (
      <Icon
        className="inline-block h-6 w-6 text-white flex-shrink-0"
        strokeWidth={2.5}
      />
    );
  };

  const AssignmentCard: React.FC<AssignmentProps> = ({ assignment }) => {
    const navigate = useNavigate();

    const sections = assignment.Sections || [];
    const midpoint = Math.ceil(sections.length / 2);
    const leftColumnSections = sections.slice(0, midpoint);
    const rightColumnSections = sections.slice(midpoint);

    const formatDate = (date: string) => {
      return new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    };

    const formatAssignmentType = React.useMemo(
      () => (type: string) => {
        if (!type) return '';
        const formattedType = type
          .split('_')
          .map((word) => {
            if (word.toLowerCase() === 'seven') return '7';
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          })
          .join(' ');
        return formattedType;
      },
      []
    );

    return (
      <Card className="h-full flex flex-col overflow-hidden rounded-3xl shadow-md border border-gray-100/50 bg-white transition-all duration-200 hover:shadow-xl mt-3">
        <div className="relative bg-gradient-to-r from-teal-500 to-teal-600">
          <div className="px-6 sm:px-8 py-4">
            <div className="flex flex-row items-center gap-4">
              <div className="flex items-center gap-4 flex-1 min-w-0">
                <div className="h-12 w-12 p-2.5 flex items-center justify-center bg-white/20 backdrop-blur-sm rounded-2xl shadow-md">
                  {React.cloneElement(getAssignmentTypeIcon(assignment.type), {
                    className: 'h-6 w-6 text-white',
                  })}
                </div>
                <div className="min-w-0">
                  <h3 className="text-xl font-bold text-white tracking-tight truncate">
                    {formatAssignmentType(assignment.type)} Lesson
                  </h3>
                  {assignment.forDate && (
                    <p className="mt-1.5 text-sm font-medium text-teal-100">
                      Due {formatDate(assignment.forDate)}
                    </p>
                  )}
                </div>
              </div>
              {isAtLeastTeacher && (
                <div className="flex-shrink-0">
                  <DropDown assignment={assignment} value={assignment.status} />
                </div>
              )}
            </div>
          </div>
        </div>

        <CardContent className="flex-grow px-6 sm:px-8 py-6">
          <ScrollArea className="h-[210px]">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4">
              {sortVerseRanges(sections).map((s: any, i: number) => (
                <div
                  key={i}
                  className="group relative bg-white border-2 border-gray-100 rounded-3xl p-4 transition-all duration-200"
                >
                  <div className="flex items-center gap-3.5">
                    <div className="flex items-center justify-center flex-shrink-0">
                      <Badge
                        variant="secondary"
                        className="w-11 h-11 rounded-xl flex items-center justify-center text-sm font-medium bg-gray-100 hover:bg-gray-100 border border-gray-100/80 text-gray-800"
                      >
                        {s.surahNumber}
                      </Badge>
                    </div>
                    <div className="min-w-0">
                      <h4 className="font-medium text-gray-900 truncate transition-colors">
                        {surahNameMap[s.surahNumber]}
                      </h4>
                      <div className="mt-1 text-sm text-gray-500">
                        {s.startVerse === s.endVerse ? (
                          <span>Verse {s.startVerse}</span>
                        ) : (
                          <span>
                            Verses {s.startVerse}-{s.endVerse}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ScrollArea>
        </CardContent>

        <CardFooter className="mt-auto border-t px-6 sm:px-8 py-6">
          <div className="w-full space-y-5">
            <div className="flex flex-col sm:flex-row md:flex-col lg:flex-row justify-between items-center sm:items-center gap-6">
              <div className="flex flex-wrap items-center gap-3">
                <div className="flex items-center gap-1.5">
                  <Badge
                    variant="destructive"
                    className="px-2.5 py-1 bg-red-50 text-red-700 hover:bg-red-50 border-red-200"
                  >
                    {assignment.reviewCounts?.major || 0}
                  </Badge>
                  <span className="text-sm font-medium text-gray-900">
                    Major
                  </span>
                </div>
                <div className="hidden sm:block w-px h-4 bg-gray-200" />
                <div className="flex items-center gap-1.5">
                  <Badge
                    variant="secondary"
                    className="px-2.5 py-1 bg-orange-50 text-orange-700 hover:bg-orange-50 border-orange-200"
                  >
                    {assignment.reviewCounts?.minor || 0}
                  </Badge>
                  <span className="text-sm font-medium text-gray-900">
                    Minor
                  </span>
                </div>
                <div className="hidden sm:block w-px h-4 bg-gray-200" />
                <div className="flex items-center gap-1.5">
                  <Badge
                    variant="outline"
                    className="px-2.5 py-1 bg-yellow-50 text-yellow-700 hover:bg-yellow-50 border-yellow-200"
                  >
                    {assignment.reviewCounts?.tajweed || 0}
                  </Badge>
                  <span className="text-sm font-medium text-gray-900">
                    Tajweed
                  </span>
                </div>
              </div>

              {isAtLeastTeacher && (
                <div className="flex items-center gap-2 w-full sm:w-auto md:w-full lg:w-auto">
                  <Button
                    variant="outline"
                    size="sm"
                    className="flex-1 sm:flex-none md:flex-1 lg:flex-1 text-teal-600 hover:text-teal-700 hover:bg-teal-50 transition-all duration-200 rounded-xl border-teal-200"
                    onClick={() => {
                      if (user) {
                        navigate(`/student/${assignment.id}/edit-assignment`, {
                          state: {
                            assignment: {
                              ...assignment,
                              studentId: user.id,
                              type: assignment.type,
                            },
                            verses: assignment.Sections.reduce(
                              (
                                acc: {
                                  [key: number]: {
                                    startVerse: number;
                                    endVerse: number;
                                  }[];
                                },
                                section
                              ) => {
                                if (!acc[section.surahNumber]) {
                                  acc[section.surahNumber] = [];
                                }
                                acc[section.surahNumber].push({
                                  startVerse: section.startVerse,
                                  endVerse: section.endVerse,
                                });
                                return acc;
                              },
                              {}
                            ),
                            user: user,
                            mode: AssignmentMutationMode.update,
                          },
                        });
                      }
                    }}
                  >
                    <Pencil className="h-4 w-4 mr-1.5" />
                    Edit
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    className="flex-1 sm:flex-none md:flex-1 lg:flex-1 text-teal-600 hover:text-teal-700 hover:bg-teal-50 transition-all duration-200 rounded-xl border-teal-200"
                    onClick={() => {
                      if (user) {
                        navigate(`/student/${assignment.id}/mistakes`, {
                          state: { user },
                        });
                      }
                    }}
                  >
                    <CircleAlert className="h-4 w-4 mr-1.5" />
                    Review
                  </Button>
                </div>
              )}
            </div>
          </div>
        </CardFooter>
      </Card>
    );
  };

  const renderAssignmentSection = (type: AssignmentType) => {
    const assignmentsOfType = assignments.filter(
      (a: Assignment) => a.type === type
    );

    if (isAtLeastTeacher && assignmentsOfType.length === 0) {
      return (
        <Card
          className="group h-full min-h-[300px] flex flex-col items-center justify-center overflow-hidden rounded-3xl shadow-md hover:shadow-lg transition-all duration-200 cursor-pointer border border-gray-100/50 hover:border-teal-200 mt-3"
          key={`empty-${type}`}
          onClick={() =>
            navigate(`/student/${user?.id}/create-assignment`, {
              state: {
                user: user,
                type: type,
              },
            })
          }
        >
          <div className="relative flex flex-col items-center text-center p-8">
            <div className="mb-5">
              <div className="h-16 w-16 p-3 flex items-center justify-center bg-gradient-to-br from-teal-500 to-teal-600 rounded-2xl shadow-md">
                {getAssignmentTypeIcon(type)}
              </div>
            </div>
            <h3 className="text-xl font-semibold text-gray-900 mb-2 group-hover:text-teal-600 transition-colors">
              Create{' '}
              {type.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())}{' '}
              Lesson
            </h3>
            <p className="text-gray-500 max-w-[200px] sm:max-w-sm">
              Add a new lesson to track student's progress
            </p>
          </div>
        </Card>
      );
    } else if (!isAtLeastTeacher && assignmentsOfType.length === 0) {
      return <Skeleton className="h-full w-full rounded-xl" />;
    }

    return assignmentsOfType.map((assignment: Assignment) => (
      <AssignmentCard
        key={`${type}-${assignment.id}`}
        assignment={assignment}
      />
    ));
  };

  const AttendanceChart = () => {
    const totalAttendance = Math.max(
      1,
      (attendanceSummary.present || 0) +
        (attendanceSummary.late || 0) +
        (attendanceSummary.absent || 0)
    );

    const presentPercentage = Math.round(
      ((attendanceSummary.present || 0) / totalAttendance) * 100
    );

    const chartData = [
      {
        name: 'Present',
        value: ((attendanceSummary.present || 0) / totalAttendance) * 100,
      },
      {
        name: 'Late',
        value: ((attendanceSummary.late || 0) / totalAttendance) * 100,
      },
      {
        name: 'Absent',
        value: ((attendanceSummary.absent || 0) / totalAttendance) * 100,
      },
    ].filter((item) => item.value > 0);

    const barChartData = [
      { status: 'Present', count: attendanceSummary.present || 0 },
      { status: 'Late', count: attendanceSummary.late || 0 },
      { status: 'Absent', count: attendanceSummary.absent || 0 },
    ].filter((item) => item.count > 0);

    const chartConfig = {
      present: {
        label: 'Present',
        color: '#3ed675',
      },
      late: {
        label: 'Late',
        color: '#f5e46c',
      },
      absent: {
        label: 'Absent',
        color: '#ed5858',
      },
    };

    const currentMonthFirstDate = format(startOfMonth(new Date()), 'MM/dd/yy');
    const today = format(new Date(), 'MM/dd/yy');
    const currentYearFirstDate = format(startOfYear(new Date()), 'MM/dd/yy');

    return (
      <Card className="mt-6 w-full overflow-hidden bg-white dark:bg-gray-900 rounded-[2rem] border border-gray-100 dark:border-gray-800 shadow-xl hover:shadow-2xl transition-all duration-300">
        <CardHeader className="relative overflow-hidden py-6 sm:py-8 px-6 sm:px-8 md:px-10 lg:px-12">
          <div className="absolute inset-0 bg-gradient-to-r from-teal-500 to-teal-600"></div>
          <div className="relative flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            <div className="flex items-start space-x-4">
              <div className="p-3 sm:p-3.5 bg-white/20 backdrop-blur-sm rounded-2xl shadow-md flex items-center justify-center">
                <Calendar
                  className="h-5 w-5 sm:h-6 sm:w-6 text-white"
                  strokeWidth={2}
                />
              </div>
              <div>
                <CardTitle className="text-xl sm:text-2xl font-bold text-white">
                  Attendance Insights
                </CardTitle>
                <p className="mt-1 text-sm text-teal-100 font-medium">
                  {currentTab === 'monthly'
                    ? `${currentMonthFirstDate} - ${today}`
                    : `${currentYearFirstDate} - ${today}`}
                </p>
              </div>
            </div>
            <Tabs value={currentTab} onValueChange={setCurrentTab}>
              <TabsList className="bg-white/90 p-1.5 rounded-xl h-10 mx-auto">
                <TabsTrigger
                  value="monthly"
                  className="text-gray-600 rounded-lg text-sm font-medium py-1.5 px-4 data-[state=active]:bg-teal-500 data-[state=active]:text-white hover:bg-white/50 transition-all duration-200"
                >
                  Monthly
                </TabsTrigger>
                <TabsTrigger
                  value="yearly"
                  className="text-gray-600 rounded-lg text-sm font-medium py-1.5 px-4 data-[state=active]:bg-teal-500 data-[state=active]:text-white hover:bg-white/50 transition-all duration-200"
                >
                  Yearly
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </CardHeader>

        <CardContent className="py-6 sm:py-8 px-6 sm:px-8 md:px-10 lg:px-12">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 xl:gap-16">
            <div className="relative flex flex-col items-center justify-center p-6 rounded-3xl bg-gradient-to-br from-gray-50 to-gray-100/50">
              <PieChart width={320} height={320} className="[&_*]:outline-none">
                <text
                  x={160}
                  y={160}
                  textAnchor="middle"
                  dominantBaseline="middle"
                >
                  <tspan
                    x={160}
                    y={150}
                    className="text-[28px] font-bold fill-gray-900"
                  >
                    {`${presentPercentage}%`}
                  </tspan>
                  <tspan
                    x={160}
                    y={180}
                    className="text-sm font-medium fill-gray-700"
                  >
                    Present Rate
                  </tspan>
                </text>
                <Pie
                  data={chartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={100}
                  outerRadius={140}
                  strokeWidth={0}
                  animationDuration={1000}
                  animationBegin={0}
                >
                  {chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        chartConfig[
                          entry.name.toLowerCase() as keyof typeof chartConfig
                        ].color
                      }
                      opacity={0.9}
                    />
                  ))}
                </Pie>
                <Tooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      const data = payload[0];
                      return (
                        <div className="bg-white/90 backdrop-blur-sm px-4 py-3 rounded-2xl shadow-lg border border-gray-100">
                          <div className="flex items-center gap-2">
                            <div
                              className="w-3 h-3 rounded-full"
                              style={{
                                backgroundColor: data.payload.fill,
                              }}
                            />
                            <span className="font-medium text-gray-900">
                              {data.name}
                            </span>
                            <span className="font-semibold text-gray-600">
                              {`${Number(data.value).toFixed(1)}%`}
                            </span>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
              </PieChart>

              <div className="mt-8 flex items-center justify-center gap-8">
                {[
                  { label: 'Present', color: chartConfig.present.color },
                  { label: 'Late', color: chartConfig.late.color },
                  { label: 'Absent', color: chartConfig.absent.color },
                ].map((item) => (
                  <div key={item.label} className="flex items-center gap-2.5">
                    <div
                      className="w-3.5 h-3.5 rounded-full shadow-sm"
                      style={{ backgroundColor: item.color }}
                    />
                    <span className="text-sm font-medium text-gray-700">
                      {item.label}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex-1 p-6 rounded-3xl bg-gradient-to-br from-gray-50 to-gray-100/50">
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    data={barChartData}
                    className="[&_*]:outline-none"
                    margin={{ top: 40, right: 0, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid
                      strokeDasharray="3 3"
                      vertical={false}
                      stroke="#E5E7EB"
                      opacity={0.5}
                    />
                    <XAxis
                      dataKey="status"
                      tick={{ fill: '#4B5563', fontSize: 13, fontWeight: 500 }}
                      axisLine={false}
                      tickLine={false}
                      dy={8}
                    />
                    <Tooltip
                      cursor={{ fill: '#F3F4F6' }}
                      content={({ active, payload }) => {
                        if (active && payload && payload.length) {
                          const data = payload[0];
                          return (
                            <div className="bg-white/90 backdrop-blur-sm px-4 py-3 rounded-2xl shadow-lg border border-gray-100">
                              <div className="flex items-center gap-2">
                                <div
                                  className="w-3 h-3 rounded-full"
                                  style={{
                                    backgroundColor:
                                      chartConfig[
                                        data.payload.status.toLowerCase() as keyof typeof chartConfig
                                      ].color,
                                  }}
                                />
                                <span className="font-medium text-gray-900">
                                  {data.payload.status}
                                </span>
                                <span className="font-semibold text-gray-600">
                                  {data.value}
                                </span>
                              </div>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Bar dataKey="count" radius={[6, 6, 0, 0]} barSize={48}>
                      {barChartData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={
                            chartConfig[
                              entry.status.toLowerCase() as keyof typeof chartConfig
                            ].color
                          }
                          opacity={0.9}
                        />
                      ))}
                      <LabelList
                        dataKey="count"
                        position="top"
                        fill="#374151"
                        fontSize={13}
                        fontWeight={600}
                        dy={-12}
                      />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };

  if (userLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Skeleton className="h-12 w-12 rounded-full" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pb-14">
      <HeaderNav title={<span>{user?.name}</span>} />
      <div className="p-4 pt-6 pb-24">
        <div className="grid grid-cols-1 gap-6 w-full mx-auto md:max-w-7xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {!assignmentsLoading &&
              assignmentTypeOrder.map((type: AssignmentType) => (
                <React.Fragment key={type}>
                  {renderAssignmentSection(type)}
                </React.Fragment>
              ))}
          </div>
          {pendingAssignment && (
            <PartialPassSelector
              isOpen={isStatusModalOpen}
              onClose={() => {
                setIsStatusModalOpen(false);
                setPendingAssignment(null);
                setSelectedSections({});
              }}
              onConfirm={handleStatusModalConfirm}
              sections={
                (pendingAssignment.Sections as Array<
                  Section & { id: number }
                >) || []
              }
              selectedSections={selectedSections}
              setSelectedSections={setSelectedSections}
              onSectionToggle={handleSectionToggle}
            />
          )}
          <div className="col-span-full">
            <AttendanceChart />
          </div>
        </div>
      </div>
    </div>
  );
}
