import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from './ui/button';
import { Label } from './ui/label';
import HeaderNav from './HeaderNav';
import { ModernAudioRecorderPlayer } from '../component/audio-player';
import { useGetAssignment } from '../hooks/useGetAssignment';
import { surahs } from '../utils/surahUtils';
import { cn } from '../lib/utils';
import { Check, ChevronRight } from 'lucide-react';
import * as SliderPrimitive from '@radix-ui/react-slider';

type MistakeType = 'Major' | 'Minor' | 'Tajweed';

interface MistakeFormData {
  sectionId: number | null;
  type: MistakeType | null;
  startVerse: number | null;
  endVerse: number | null;
  recordingTime: number;
}

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => {
  const [start, end] = props.value as number[];
  const isSingleVerse = start === end;

  return (
    <SliderPrimitive.Root
      ref={ref}
      className={cn(
        'relative flex items-center select-none touch-none w-full h-5',
        className
      )}
      {...props}
    >
      <SliderPrimitive.Track className="bg-gray-200 relative grow rounded-full h-2">
        <SliderPrimitive.Range className="absolute bg-teal-700 rounded-full h-full" />
      </SliderPrimitive.Track>
      {isSingleVerse ? (
        <SliderPrimitive.Thumb className="block w-5 h-5 bg-white border-2 border-teal-700 rounded-full shadow focus:outline-none hover:scale-110 transition-all duration-150 ease-out will-change-transform" />
      ) : (
        [0, 1].map((thumbIndex) => (
          <SliderPrimitive.Thumb
            key={thumbIndex}
            className="block w-5 h-5 bg-white border-2 border-teal-700 rounded-full shadow focus:outline-none hover:scale-110 transition-all duration-150 ease-out will-change-transform"
          />
        ))
      )}
    </SliderPrimitive.Root>
  );
});
Slider.displayName = SliderPrimitive.Root.displayName;

export default function AssignmentMistakes() {
  const params = useParams();
  const { assignmentId } = params;
  const { assignment, loading: assignmentLoading } = useGetAssignment(
    +assignmentId!
  );

  const [selectedSectionId, setSelectedSectionId] = React.useState<
    number | null
  >(null);
  const [mistakeFormData, setMistakeFormData] = React.useState<MistakeFormData>(
    {
      sectionId: null,
      type: null,
      startVerse: null,
      endVerse: null,
      recordingTime: 0,
    }
  );

  const handleSectionSelect = React.useCallback(
    (sectionId: number) => {
      if (!assignment?.Sections) return;

      setSelectedSectionId((prev) => (prev === sectionId ? null : sectionId));
      setMistakeFormData((prev) => {
        if (prev.sectionId === sectionId) {
          return {
            ...prev,
            sectionId: null,
            type: null,
            startVerse: null,
            endVerse: null,
          };
        }

        const section = assignment.Sections.find((s) => s.id === sectionId);
        if (!section) return prev;

        return {
          ...prev,
          sectionId,
          type: null,
          startVerse: section.startVerse,
          endVerse: section.endVerse,
        };
      });
    },
    [assignment?.Sections]
  );

  const handleMistakeTypeSelect = (type: MistakeType) => {
    setMistakeFormData((prev) => ({
      ...prev,
      type,
    }));
  };

  const handleMistakeSubmit = () => {
    // Implement mistake submission logic here
    console.log('Submitting mistake:', mistakeFormData);
  };

  if (assignmentLoading) {
    return <div>Loading...</div>;
  }

  if (!assignment) {
    return <div>Assignment not found</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50/50">
      <HeaderNav title="Record Mistakes" />
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-5xl mx-auto space-y-6">
          {/* Section Selection */}
          <div className="grid gap-4">
            <h3 className="text-xl font-bold text-gray-900">Select Section</h3>
            <div className="grid gap-4">
              {assignment.Sections?.map((section) => {
                const surah = surahs.find(
                  (s) => s.surahNumber === section.surahNumber
                );
                if (!surah) return null;

                const isSelected = selectedSectionId === surah.surahNumber;

                return (
                  <div
                    key={section.id}
                    className={cn(
                      'bg-white rounded-3xl border cursor-pointer overflow-hidden transition-all duration-300',
                      isSelected ? 'border shadow-md' : 'hover:shadow-md'
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleSectionSelect(surah.surahNumber);
                    }}
                  >
                    <div className="p-6 sm:p-8">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-4">
                          <div
                            className={cn(
                              'w-11 h-11 rounded-xl flex items-center justify-center text-sm font-medium transition-colors',
                              isSelected
                                ? 'bg-primary/10 text-primary'
                                : 'bg-gray-100 text-gray-500'
                            )}
                          >
                            {surah.surahNumber}
                          </div>
                          <div className="space-y-1">
                            <h3 className="text-lg font-semibold text-gray-900">
                              {surah.name}
                            </h3>
                            <p className="text-sm text-gray-500">
                              Verses {section.startVerse} - {section.endVerse}
                            </p>
                          </div>
                        </div>
                        <div
                          className={cn(
                            'w-8 h-8 rounded-full flex items-center justify-center',
                            isSelected ? 'bg-primary text-white' : 'bg-gray-100'
                          )}
                        >
                          {isSelected ? (
                            <Check className="w-5 h-5" />
                          ) : (
                            <ChevronRight className="w-5 h-5" />
                          )}
                        </div>
                      </div>

                      {isSelected && (
                        <div className="mt-6 space-y-6">
                          {/* Mistake Type Selection */}
                          <div className="space-y-4">
                            <h4 className="font-medium text-gray-700">
                              Type of Mistake
                            </h4>
                            <div className="flex gap-3">
                              {['Major', 'Minor', 'Tajweed'].map((type) => (
                                <Button
                                  key={type}
                                  variant={
                                    mistakeFormData.type === type
                                      ? 'default'
                                      : 'outline'
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleMistakeTypeSelect(
                                      type as MistakeType
                                    );
                                  }}
                                  className={cn(
                                    'flex-1 h-10 rounded-xl',
                                    mistakeFormData.type === type
                                      ? 'shadow-sm'
                                      : 'hover:bg-gray-50'
                                  )}
                                >
                                  {type}
                                </Button>
                              ))}
                            </div>
                          </div>

                          {/* Verse Selection */}
                          <div className="space-y-4">
                            <div className="flex justify-between items-center">
                              <Label className="text-sm font-medium text-gray-700">
                                Verse Range
                              </Label>
                              <span className="text-sm font-semibold text-gray-900">
                                {mistakeFormData.startVerse ===
                                mistakeFormData.endVerse
                                  ? `${mistakeFormData.startVerse}`
                                  : `${mistakeFormData.startVerse} - ${mistakeFormData.endVerse}`}
                              </span>
                            </div>
                            <Slider
                              min={section.startVerse}
                              max={section.endVerse}
                              step={1}
                              value={[
                                mistakeFormData.startVerse ||
                                  section.startVerse,
                                mistakeFormData.endVerse || section.endVerse,
                              ]}
                              onValueChange={(value) => {
                                setMistakeFormData((prev) => ({
                                  ...prev,
                                  startVerse: value[0],
                                  endVerse: value[1],
                                }));
                              }}
                              className="mt-2"
                            />
                          </div>

                          {/* Audio Recording */}
                          {mistakeFormData.type && (
                            <div className="space-y-4">
                              <h4 className="font-medium text-gray-700">
                                Record Audio
                              </h4>
                              <div
                                className="bg-gray-50 rounded-2xl p-6"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <ModernAudioRecorderPlayer />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Submit Button */}
          {selectedSectionId && mistakeFormData.type && (
            <div>
              <Button
                onClick={handleMistakeSubmit}
                disabled={!mistakeFormData.type}
                className="w-full h-12 text-lg font-medium rounded-2xl"
              >
                Submit Mistake
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
