'use client';

import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as SliderPrimitive from '@radix-ui/react-slider';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from './ui/accordion';
import { Button } from './ui/button';
import { Card, CardContent } from './ui/card';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Switch } from './ui/switch';
import { Textarea } from './ui/textarea';
import { ScrollArea } from './ui/scroll-area';
import { Badge } from './ui/badge';
import { AlertCircle, Bookmark, ChevronDown, CircleCheck, Save, Search, X, Plus, Minus } from 'lucide-react';
import HeaderNav from './HeaderNav';
import { useGetAssignment } from '../hooks/useGetAssignment';
import { AssignmentMutationMode } from '../types/common';
import { Surah, surahs } from '../utils/surahUtils';
import { cn } from '../lib/utils';

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex items-center select-none touch-none w-full h-5',
      className
    )}
    {...props}
  >
    <SliderPrimitive.Track className="bg-gray-200 relative grow rounded-full h-2">
      <SliderPrimitive.Range className="absolute bg-teal-700 rounded-full h-full" />
    </SliderPrimitive.Track>
    {[0, 1].map((thumbIndex) => (
      <SliderPrimitive.Thumb
        key={thumbIndex}
        className="block w-5 h-5 bg-white border-2 border-teal-700 rounded-full shadow focus:outline-none hover:scale-110 transition-all duration-150 ease-out will-change-transform"
      />
    ))}
  </SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

const sortVerseRanges = (
  ranges: { startVerse: number | null; endVerse: number | null }[]
) => {
  return [...ranges].sort((a, b) => {
    if (a.startVerse === null || b.startVerse === null) return 0;
    return a.startVerse - b.startVerse;
  });
};

export default function EditAssignment() {
  const [showSurahSelector, setShowSurahSelector] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedSurahs, setSelectedSurahs] = React.useState<number[]>([]);
  const { state } = useLocation();
  const params = useParams();
  const { assignmentId } = params;
  const { assignment, loading: assignmentLoading } = useGetAssignment(
    +assignmentId!
  );
  const [, /*expandedAccordionItems*/ setExpandedAccordionItems] =
    React.useState<string[]>([]);
  const [verses, setVerses] = React.useState<{
    [key: number]: { startVerse: number | null; endVerse: number | null }[];
  }>({});
  const [initialVerses, setInitialVerses] = React.useState<{
    [key: number]: { startVerse: number | null; endVerse: number | null }[];
  }>({});
  const [entireVerse, setEntireVerse] = React.useState<{
    [key: number]: boolean;
  }>({});
  const [initialEntireVerse, setInitialEntireVerse] = React.useState<{
    [key: number]: boolean;
  }>({});
  const [inputValues, setInputValues] = React.useState<{
    [key: number]: { startVerse: string; endVerse: string }[];
  }>({});
  const navigate = useNavigate();
  const [, /*isNoteOpen*/ setIsNoteOpen] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [errors, setErrors] = React.useState<{ [key: number]: string }>({});

  // Scroll to top on mount
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Filter available surahs (those not already in the assignment)
  const availableSurahs = React.useMemo(() => {
    const assignedSurahNumbers =
      assignment?.Sections?.map((s) => s.surahNumber) || [];
    return surahs.filter(
      (surah) => !assignedSurahNumbers.includes(surah.surahNumber)
    );
  }, [assignment?.Sections]);

  // Filter surahs based on search term
  const displayedSurahs = React.useMemo(() => {
    if (!searchTerm) return availableSurahs;
    return availableSurahs.filter((surah) =>
      surah.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [availableSurahs, searchTerm]);

  React.useEffect(() => {
    if (state?.assignment && !assignmentLoading) {
      const newVerses: {
        [key: number]: { startVerse: number | null; endVerse: number | null }[];
      } = {};
      const newEntireVerse: { [key: number]: boolean } = {};
      const expandedItems: string[] = [];
      const currentSurahs: number[] = [];
      const newInputValues: {
        [key: number]: { startVerse: string; endVerse: string }[];
      } = {};

      Object.entries(state.verses).forEach(([surahNumber, ranges]) => {
        const surahNum = parseInt(surahNumber);
        currentSurahs.push(surahNum);
        const surah = surahs.find((s) => s.surahNumber === surahNum);
        if (surah) {
          const typedRanges = ranges as {
            startVerse: number;
            endVerse: number;
          }[];
          newVerses[surahNum] = typedRanges.map((range) => ({
            startVerse: range.startVerse,
            endVerse: range.endVerse,
          }));
          newInputValues[surahNum] = typedRanges.map((range) => ({
            startVerse: range.startVerse.toString(),
            endVerse: range.endVerse.toString(),
          }));

          const isEntireSurah =
            typedRanges.length === 1 &&
            typedRanges[0].startVerse === surah.startVerse &&
            typedRanges[0].endVerse === surah.endVerse;

          newEntireVerse[surahNum] = isEntireSurah;
          expandedItems.push(surahNum.toString());
        }
      });

      setSelectedSurahs(currentSurahs);
      setVerses(newVerses);
      setInitialVerses(newVerses);
      setEntireVerse(newEntireVerse);
      setInitialEntireVerse(newEntireVerse);
      setInputValues(newInputValues);
      setExpandedAccordionItems(expandedItems);
    }
  }, [state, assignmentLoading]);

  React.useEffect(() => {
    const hasVerseChanges = Object.keys(verses).some((surahNumber) => {
      const initialVerse = initialVerses[parseInt(surahNumber)];
      const currentVerse = verses[parseInt(surahNumber)];
      return JSON.stringify(initialVerse) !== JSON.stringify(currentVerse);
    });

    const hasEntireVerseChanges = Object.keys(entireVerse).some(
      (surahNumber) => {
        return (
          initialEntireVerse[parseInt(surahNumber)] !==
          entireVerse[parseInt(surahNumber)]
        );
      }
    );

    setHasChanges(hasVerseChanges || hasEntireVerseChanges);
  }, [verses, entireVerse, initialVerses, initialEntireVerse]);

  const removeSurah = (surahNumber: number) => {
    if (selectedSurahs.length <= 1) {
      return; // Prevent removing the last surah
    }
    setSelectedSurahs((prev) => prev.filter((num) => num !== surahNumber));
    setVerses((prev) => {
      const newVerses = { ...prev };
      delete newVerses[surahNumber];
      return newVerses;
    });
    setEntireVerse((prev) => {
      const newEntireVerse = { ...prev };
      delete newEntireVerse[surahNumber];
      return newEntireVerse;
    });
    setInputValues((prev) => {
      const newInputValues = { ...prev };
      delete newInputValues[surahNumber];
      return newInputValues;
    });
    setExpandedAccordionItems((prev) =>
      prev.filter((id) => id !== surahNumber.toString())
    );
  };

  const handleInputChange = (
    surahNumber: number,
    type: 'startVerse' | 'endVerse',
    value: string,
    index: number
  ) => {
    const numValue = value === '' ? null : parseInt(value, 10);

    setInputValues((prev) => {
      const updatedRanges = [...(prev[surahNumber] || [])];
      updatedRanges[index] = {
        ...updatedRanges[index],
        [type]: value,
      };
      return {
        ...prev,
        [surahNumber]: updatedRanges,
      };
    });

    setVerses((prev) => {
      const updatedRanges = [...(prev[surahNumber] || [])];
      updatedRanges[index] = {
        ...updatedRanges[index],
        [type]: numValue,
      };
      return {
        ...prev,
        [surahNumber]: updatedRanges,
      };
    });

    setHasChanges(true);
  };

  const handleVerseBlur = (
    surahNumber: number,
    type: 'startVerse' | 'endVerse',
    surah: Surah,
    index: number
  ) => {
    setInputValues((prev) => {
      const updatedRanges = [...(prev[surahNumber] || [])];
      const currentValue = updatedRanges[index][type];

      const numValue = parseInt(currentValue);
      const min = surah.startVerse;
      const max = surah.endVerse;

      if (isNaN(numValue) || numValue < min || numValue > max) {
        updatedRanges[index] = { ...updatedRanges[index], [type]: '' };
      } else {
        updatedRanges[index] = {
          ...updatedRanges[index],
          [type]: numValue.toString(),
        };
      }

      return {
        ...prev,
        [surahNumber]: updatedRanges,
      };
    });

    setVerses((prev) => {
      const updatedRanges = [...(prev[surahNumber] || [])];
      const currentValue = updatedRanges[index][type];

      const numValue = parseInt(currentValue?.toString() || '');
      const min = surah.startVerse;
      const max = surah.endVerse;

      if (isNaN(numValue) || numValue < min || numValue > max) {
        updatedRanges[index] = { ...updatedRanges[index], [type]: null };
      } else {
        updatedRanges[index] = { ...updatedRanges[index], [type]: numValue };
      }

      return {
        ...prev,
        [surahNumber]: updatedRanges,
      };
    });

    setHasChanges(true);
    setTimeout(() => {
      validateVerses();
    }, 0);
  };

  const handleInputBlur = (
    surahNumber: number,
    type: 'startVerse' | 'endVerse',
    surah: Surah,
    index: number
  ) => {
    handleVerseBlur(surahNumber, type, surah, index);
    validateVerses();
  };

  const addVerseRange = (surahNumber: number) => {
    const surah = surahs.find((s) => s.surahNumber === surahNumber);
    if (!surah) return;

    setVerses((prev) => ({
      ...prev,
      [surahNumber]: [
        ...(prev[surahNumber] || []),
        { startVerse: surah.startVerse, endVerse: surah.startVerse },
      ],
    }));
    setInputValues((prev) => ({
      ...prev,
      [surahNumber]: [
        ...(prev[surahNumber] || []),
        {
          startVerse: surah.startVerse.toString(),
          endVerse: surah.startVerse.toString(),
        },
      ],
    }));
    validateVerses();
  };

  const removeVerseRange = (surahNumber: number, index: number) => {
    setVerses((prev) => ({
      ...prev,
      [surahNumber]: prev[surahNumber].filter((_, i) => i !== index),
    }));
    setInputValues((prev) => ({
      ...prev,
      [surahNumber]: prev[surahNumber].filter((_, i) => i !== index),
    }));
  };

  const handleConfirmLesson = () => {
    if (!validateVerses()) {
      return;
    }
    const selectedData = {
      verses,
      mode: AssignmentMutationMode.update,
      assignmentId,
    };
    navigate(`/student/${state.user?.id}/review-assignment`, {
      state: {
        assignment: { id: assignmentId, Sections: { ...selectedData } },
        user: state.user,
        ...selectedData,
        mode: AssignmentMutationMode.update,
      },
      replace: true,
    });
  };

  const handleSaveNote = () => {
    // Implement note saving logic here
    setIsNoteOpen(false);
  };

  const handleEntireVerseToggle = (
    surahNumber: number,
    checked: boolean,
    surah: Surah
  ) => {
    setEntireVerse((prev) => ({
      ...prev,
      [surahNumber]: checked,
    }));
    setInputValues((prev) => ({
      ...prev,
      [surahNumber]: checked
        ? [
            {
              startVerse: surah.startVerse.toString(),
              endVerse: surah.endVerse.toString(),
            },
          ]
        : [{ startVerse: '', endVerse: '' }],
    }));

    if (checked) {
      setVerses((prev) => ({
        ...prev,
        [surah.surahNumber]: [
          {
            startVerse: surah.startVerse,
            endVerse: surah.endVerse,
          },
        ],
      }));
    } else {
      setVerses((prev) => ({
        ...prev,
        [surahNumber]: [
          {
            startVerse: null,
            endVerse: null,
          },
        ],
      }));
    }
  };

  const validateVerses = () => {
    const newErrors: { [key: number]: string } = {};
    selectedSurahs.forEach((surahNumber) => {
      const surahVerses = verses[surahNumber];
      const surah = surahs.find((s) => s.surahNumber === surahNumber);
      if (!surah) return;

      if (
        !entireVerse[surahNumber] &&
        (!surahVerses || surahVerses.length === 0)
      ) {
        newErrors[surahNumber] =
          'Please select entire surah or specify verse ranges';
      } else if (!entireVerse[surahNumber]) {
        const invalidRanges = surahVerses.some(
          (range) =>
            range.startVerse === null ||
            range.endVerse === null ||
            (range.startVerse !== null &&
              range.endVerse !== null &&
              range.startVerse > range.endVerse) ||
            (range.startVerse !== null &&
              range.startVerse < surah.startVerse) ||
            (range.endVerse !== null && range.endVerse > surah.endVerse)
        );
        if (invalidRanges) {
          newErrors[surahNumber] = 'Please ensure all verse ranges are valid';
        }
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const NoteFormContent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement> & { surahName: string; verses: string }
  >(({ surahName, verses, ...props }, ref) => (
    <div {...props} ref={ref}>
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="note">
            Note for lesson {surahName}, {verses}
          </Label>
          <Textarea
            id="note"
            placeholder="Write your note here..."
            className="min-h-[200px] w-full rounded-xl border p-4 resize-none"
            rows={5}
          />
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <Button onClick={handleSaveNote} className="rounded-xl">
          <Save className="mr-2 h-4 w-4" />
          Save
        </Button>
      </div>
    </div>
  ));
  NoteFormContent.displayName = 'NoteFormContent';

  const formattedTitle = React.useMemo(() => {
    if (!assignment?.type) return 'Edit Lesson';
    const formattedType = assignment.type
      .split('_')
      .map((word) => {
        if (word.toLowerCase() === 'seven') return '7';
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
    return `Edit ${formattedType} Lesson`;
  }, [assignment?.type]);

  return (
    <div className="min-h-screen bg-gray-50/50">
      <HeaderNav title={formattedTitle} />
      <div className="px-4 py-6">
        <div className="w-full pb-24 mx-auto border-none shadow-none md:w-4/5 xl:w-3/5">
          {/* Add Surah Button */}
          <div className="flex justify-between items-center mb-6">
            <div className="space-y-1.5">
              <h2 className="text-2xl font-bold text-gray-900">
                Edit Assignment
              </h2>
              <p className="text-sm text-gray-500">
                Make changes to the assignment
              </p>
            </div>
            <Button
              variant="outline"
              onClick={() => setShowSurahSelector((prev) => !prev)}
              className="flex items-center gap-2 rounded-full px-6 hover:bg-primary hover:text-white transition-all duration-300 border-2"
            >
              <Bookmark className="w-4 h-4" />
              Add Surah
            </Button>
          </div>

          {/* Selected Surahs Summary */}
          {selectedSurahs.length > 0 && (
            <div className="mb-6">
              <div className="mb-3">
                <h3 className="text-sm font-medium text-gray-700">
                  Selected Surahs
                </h3>
              </div>
              <div className="flex flex-wrap gap-2">
                {selectedSurahs.map((surahNumber) => {
                  const surah = surahs.find(
                    (s) => s.surahNumber === surahNumber
                  );
                  if (!surah) return null;
                  const hasVerses =
                    verses[surahNumber]?.length > 0 &&
                    verses[surahNumber].every(
                      (range) =>
                        range.startVerse !== null && range.endVerse !== null
                    );
                  return (
                    <div
                      key={surahNumber}
                      className={`inline-flex items-center gap-2 px-3 py-1.5 rounded-full text-sm transition-colors ${
                        hasVerses
                          ? 'bg-primary/10 text-primary'
                          : 'bg-gray-100 text-gray-600'
                      }`}
                    >
                      <span className="font-medium">{surah.name}</span>
                      {hasVerses && (
                        <span className="text-xs opacity-80">
                          {verses[surahNumber].length === 1
                            ? `Verses ${verses[surahNumber][0].startVerse}-${verses[surahNumber][0].endVerse}`
                            : `${verses[surahNumber].length} ranges`}
                        </span>
                      )}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          removeSurah(surahNumber);
                        }}
                        disabled={selectedSurahs.length <= 1}
                        className={`ml-1 transition-opacity ${
                          selectedSurahs.length <= 1
                            ? 'opacity-30 cursor-not-allowed'
                            : 'opacity-50 hover:opacity-100'
                        }`}
                      >
                        <X className="h-3 w-3" />
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* Surah Selector */}
          {showSurahSelector && (
            <Card className="rounded-3xl border shadow-lg mb-6 overflow-hidden">
              <CardContent className="p-0">
                <div className="p-6 pb-4 border-b bg-gray-50/50">
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                    <Input
                      type="text"
                      placeholder="Search Surahs"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      icon={<Search className="w-4 h-4 opacity-50" />}
                    />
                  </div>
                </div>

                {displayedSurahs.length === 0 ? (
                  <div className="flex flex-col items-center justify-center p-12 space-y-3">
                    <div className="rounded-full bg-gray-100 p-3">
                      <AlertCircle className="h-6 w-6 text-gray-400" />
                    </div>
                    <p className="text-lg font-medium text-gray-500">
                      {searchTerm ? 'No surahs found' : 'No surahs available'}
                    </p>
                    <p className="text-sm text-gray-400 text-center max-w-sm">
                      {searchTerm
                        ? 'Try searching with a different term or surah number'
                        : "You've added all available surahs to your assignment"}
                    </p>
                  </div>
                ) : (
                  <ScrollArea className="h-[400px]">
                    <div className="grid grid-cols-1 divide-y">
                      {displayedSurahs.map((surah) => {
                        const isAlreadySelected = selectedSurahs.includes(
                          surah.surahNumber
                        );
                        return (
                          <div
                            key={surah.surahNumber}
                            className={`group px-6 py-4 bg-white hover:bg-gray-50 transition-colors ${
                              isAlreadySelected
                                ? 'opacity-50 cursor-not-allowed'
                                : 'cursor-pointer'
                            }`}
                            onClick={() => {
                              if (isAlreadySelected) return;

                              const newVerses = { ...verses };
                              const newEntireVerse = { ...entireVerse };
                              const newInputValues = { ...inputValues };

                              newVerses[surah.surahNumber] = [
                                {
                                  startVerse: null,
                                  endVerse: null,
                                },
                              ];
                              newEntireVerse[surah.surahNumber] = false;
                              newInputValues[surah.surahNumber] = [
                                { startVerse: '', endVerse: '' },
                              ];

                              setVerses(newVerses);
                              setEntireVerse(newEntireVerse);
                              setInputValues(newInputValues);
                              setExpandedAccordionItems((prev) => [
                                ...prev,
                                surah.surahNumber.toString(),
                              ]);
                              setSelectedSurahs((prev) => [
                                ...prev,
                                surah.surahNumber,
                              ]);
                              setShowSurahSelector(false);
                              setSearchTerm('');
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center gap-4">
                                <div className="flex-shrink-0">
                                  <Badge
                                    variant="secondary"
                                    className="w-10 h-10 rounded-xl flex items-center justify-center text-sm font-medium bg-gray-100 border-0 text-gray-800"
                                  >
                                    {surah.surahNumber}
                                  </Badge>
                                </div>
                                <div>
                                  <h3 className="font-medium text-gray-900 group-hover:text-primary transition-colors">
                                    {surah.name}
                                  </h3>
                                  <p className="text-sm text-gray-500">
                                    {surah.endVerse - surah.startVerse + 1}{' '}
                                    verses
                                  </p>
                                </div>
                              </div>
                              {isAlreadySelected ? (
                                <Badge
                                  variant="secondary"
                                  className="rounded-full"
                                >
                                  Added
                                </Badge>
                              ) : (
                                <CircleCheck className="h-5 w-5 text-primary opacity-0 group-hover:opacity-100 transition-opacity" />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </ScrollArea>
                )}
              </CardContent>
            </Card>
          )}

          {/* Existing Sections */}
          {selectedSurahs.length > 0 && (
            <Accordion type="multiple" className="space-y-3">
              {selectedSurahs.map((surahNumber) => {
                const surah = surahs.find((s) => s.surahNumber === surahNumber);
                if (!surah) return null;

                const hasVerses =
                  verses[surahNumber]?.length > 0 &&
                  verses[surahNumber].every(
                    (range) =>
                      range.startVerse !== null && range.endVerse !== null
                  );

                return (
                  <AccordionItem
                    key={surah.surahNumber}
                    value={surah.surahNumber.toString()}
                    className={`bg-white rounded-3xl overflow-hidden transition-all ${
                      hasVerses
                        ? 'border-2 border-gray-100'
                        : 'border-2 border-gray-100'
                    }`}
                  >
                    <AccordionTrigger className="px-4 py-4 sm:px-6 hover:no-underline [&[data-state=open]]:bg-gray-50/50">
                      <div className="flex items-center justify-between w-full">
                        <div className="flex items-center gap-4">
                          <div
                            className={`w-11 h-11 rounded-xl flex items-center justify-center text-sm font-medium transition-colors ${
                              hasVerses
                                ? 'bg-primary/10 text-primary'
                                : 'bg-gray-100 text-gray-800'
                            }`}
                          >
                            {surah.surahNumber}
                          </div>
                          <div className="flex flex-col items-start">
                            <h3 className="font-medium text-gray-900">
                              {surah.name}
                            </h3>
                            <p className="text-sm text-gray-500">
                              {hasVerses
                                ? verses[surahNumber].length === 1
                                  ? `Verses ${verses[surahNumber][0].startVerse}-${verses[surahNumber][0].endVerse}`
                                  : `${verses[surahNumber].length} ranges`
                                : 'No verses selected'}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center gap-3">
                          <div className="text-xs px-3 py-1 rounded-full bg-gray-100 text-gray-500">
                            {surah.endVerse - surah.startVerse + 1} verses
                          </div>
                          <ChevronDown className="h-4 w-4 shrink-0 text-gray-500 transition-transform duration-200" />
                        </div>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className="px-6 pt-4 pb-6">
                      <div className="space-y-6">
                        <div className="flex items-center gap-4">
                          <Switch
                            id={`entire-${surah.surahNumber}`}
                            checked={entireVerse[surah.surahNumber] || false}
                            onCheckedChange={(checked) => {
                              handleEntireVerseToggle(
                                surah.surahNumber,
                                checked,
                                surah
                              );
                              if (checked) {
                                setErrors((prev) => {
                                  const newErrors = { ...prev };
                                  delete newErrors[surah.surahNumber];
                                  return newErrors;
                                });
                              }
                            }}
                          />
                          <Label
                            htmlFor={`entire-${surah.surahNumber}`}
                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                          >
                            Entire Surah
                          </Label>
                        </div>

                        {!entireVerse[surahNumber] && (
                          <div className="space-y-4">
                            {(inputValues[surah.surahNumber] || []).map(
                              (range, index) => (
                                <Card
                                  key={index}
                                  className="p-6 bg-gray-100/70 rounded-2xl border-none"
                                >
                                  <div className="flex justify-between items-center mb-4">
                                    <span className="text-sm font-medium text-gray-700">
                                      Range {index + 1}
                                    </span>
                                    <Button
                                      variant="ghost"
                                      size="sm"
                                      onClick={() =>
                                        removeVerseRange(
                                          surah.surahNumber,
                                          index
                                        )
                                      }
                                      className="h-8 w-8 p-0 rounded-full"
                                    >
                                      <Minus className="h-4 w-4" />
                                      <span className="sr-only">
                                        Remove range
                                      </span>
                                    </Button>
                                  </div>
                                  <div className="space-y-4">
                                    <div className="flex justify-between items-center">
                                      <Label
                                        htmlFor={`range-${surah.surahNumber}-${index}`}
                                        className="text-sm font-medium text-gray-700"
                                      >
                                        Verse Range
                                      </Label>
                                      <span className="text-sm font-semibold text-gray-900">
                                        {range.startVerse || surah.startVerse} -{' '}
                                        {range.endVerse || surah.endVerse}
                                      </span>
                                    </div>
                                    <Slider
                                      id={`range-${surah.surahNumber}-${index}`}
                                      min={surah.startVerse}
                                      max={surah.endVerse}
                                      step={1}
                                      value={[
                                        parseInt(range.startVerse) ||
                                          surah.startVerse,
                                        parseInt(range.endVerse) ||
                                          surah.endVerse,
                                      ]}
                                      onValueChange={(value) => {
                                        handleInputChange(
                                          surah.surahNumber,
                                          'startVerse',
                                          value[0].toString(),
                                          index
                                        );
                                        handleInputChange(
                                          surah.surahNumber,
                                          'endVerse',
                                          value[1].toString(),
                                          index
                                        );
                                      }}
                                      className="mt-2"
                                    />
                                  </div>
                                </Card>
                              )
                            )}
                            {errors[surah.surahNumber] && (
                              <p className="text-sm text-red-500 mt-2">
                                {errors[surah.surahNumber]}
                              </p>
                            )}
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => addVerseRange(surah.surahNumber)}
                              className="rounded-full"
                            >
                              <Plus className="h-4 w-4 mr-2" />
                              Add Range
                            </Button>
                          </div>
                        )}
                        <div className="flex justify-end">
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => removeSurah(surah.surahNumber)}
                            disabled={selectedSurahs.length <= 1}
                            className={`${
                              selectedSurahs.length <= 1
                                ? 'text-red-300 hover:text-red-300 cursor-not-allowed'
                                : 'text-red-600 hover:text-red-500'
                            }`}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                );
              })}
            </Accordion>
          )}
        </div>
      </div>
      <div className="bg-white fixed bottom-0 w-full py-3 px-4 border-t border-[#C5C5C5] flex justify-end">
        <Button
          variant="ghost"
          onClick={() => navigate(-1)}
          className="mr-3 rounded-xl"
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmLesson}
          disabled={!hasChanges}
          className="rounded-xl"
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}

