'use client';

import { useEffect, useRef, useState } from 'react';
import { Button } from './ui/button';
import { Slider } from './ui/slider';
import {
  Pause,
  Play,
  SkipBack,
  SkipForward,
  Volume2,
  Mic,
  StopCircle,
} from 'lucide-react';
import { cn } from '../lib/utils';
import { VoiceVisualizer } from './voice-visualizer';

export function ModernAudioRecorderPlayer() {
  const [isRecording, setIsRecording] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState<string | null>(null);
  const [audioData, setAudioData] = useState<number[]>([]);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const sourceRef = useRef<MediaStreamAudioSourceNode | null>(null);
  const animationRef = useRef<number | null>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(50);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const recordingTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [recordingTimestamp, setRecordingTimestamp] = useState<Date | null>(
    null
  );

  useEffect(() => {
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (recordingTimerRef.current) {
        clearInterval(recordingTimerRef.current);
      }
    };
  }, []);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: 'audio/webm' });
        const audioUrl = URL.createObjectURL(blob);
        setRecordedAudio(audioUrl);
        setDuration(recordingDuration);
        chunksRef.current = [];
      };
      mediaRecorderRef.current.start();
      setIsRecording(true);
      setRecordingDuration(0);
      setRecordingTimestamp(new Date());
      recordingTimerRef.current = setInterval(() => {
        setRecordingDuration((prev) => prev + 1);
      }, 1000);

      audioContextRef.current = new (window.AudioContext ||
        (window as any).webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      sourceRef.current =
        audioContextRef.current.createMediaStreamSource(stream);
      sourceRef.current.connect(analyserRef.current);

      visualize();
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      if (sourceRef.current) {
        sourceRef.current.disconnect();
      }
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      if (recordingTimerRef.current) {
        clearInterval(recordingTimerRef.current);
      }
    }
  };

  const visualize = () => {
    if (!analyserRef.current) return;

    analyserRef.current.fftSize = 256;
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const updateAudioData = () => {
      analyserRef.current!.getByteFrequencyData(dataArray);
      setAudioData(Array.from(dataArray));
      animationRef.current = requestAnimationFrame(updateAudioData);
    };

    updateAudioData();
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume]);

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      const audioDuration = audioRef.current.duration;
      if (isFinite(audioDuration) && audioDuration > 0) {
        setDuration(audioDuration);
        setCurrentTime(0);
      } else {
        setDuration(recordingDuration);
        setCurrentTime(0);
      }
    }
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const newTime = Math.min(audioRef.current.currentTime, duration);
      setCurrentTime(newTime);
    }
  };

  const handleVolumeChange = (value: number[]) => {
    setVolume(value[0]);
  };

  const handleProgressBarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (progressBarRef.current && audioRef.current && duration > 0) {
      const rect = progressBarRef.current.getBoundingClientRect();
      const clickX = event.clientX - rect.left;
      const clickPositionRatio = clickX / rect.width;
      const newTime = clickPositionRatio * duration;
      if (isFinite(newTime) && newTime >= 0 && newTime <= duration) {
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
      }
    }
  };

  const formatTime = (time: number) => {
    if (!isFinite(time)) return '0:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const formatRecordingTimestamp = (date: Date) => {
    return date
      .toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
      .replace(',', ' -');
  };

  return (
    <div className="flex flex-col items-center justify-center w-full p-6 md:p-8 rounded-3xl transition-all duration-300">
      {!recordedAudio ? (
        <div className="w-full text-center">
          <div className="mb-6 md:mb-8">
            <VoiceVisualizer audioData={audioData} isRecording={isRecording} />
          </div>
          <div className="flex flex-col items-center">
            {isRecording && (
              <div className="mb-4 text-lg sm:text-xl font-semibold text-gray-800">
                {formatTime(recordingDuration)}
              </div>
            )}
            <Button
              onClick={isRecording ? stopRecording : startRecording}
              className={cn(
                'rounded-full w-14 h-14 flex items-center justify-center transition-all duration-300 shadow-md',
                isRecording
                  ? 'bg-red-500 hover:bg-red-600'
                  : 'bg-teal-600 hover:bg-teal-700'
              )}
            >
              {isRecording ? (
                <span>
                  <StopCircle className="h-6 w-6 text-white flex-shrink-0" />
                </span>
              ) : (
                <span>
                  {' '}
                  <Mic className="h-6 w-6 text-white flex-shrink-0" />
                </span>
              )}
            </Button>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className="mb-4 sm:mb-6 text-lg font-semibold text-gray-800">
            {recordingTimestamp
              ? formatRecordingTimestamp(recordingTimestamp)
              : 'Recorded Audio'}
          </div>
          <div
            className="w-full bg-gray-200 rounded-full h-2 mb-4 sm:mb-6 cursor-pointer relative overflow-hidden"
            ref={progressBarRef}
            onClick={handleProgressBarClick}
          >
            <div
              className="bg-teal-600 h-full rounded-full transition-all duration-100 ease-in-out"
              style={{
                width: `${((currentTime / duration) * 100).toFixed(2)}%`,
              }}
            />
          </div>
          <div className="flex items-center justify-between text-xs font-medium sm:text-sm text-teal-700 mb-4 sm:mb-6">
            <span>{formatTime(currentTime)}</span>
            <span>{formatTime(duration)}</span>
          </div>
          <div className="flex items-center justify-between mb-4 sm:mb-6">
            <div className="flex items-center space-x-2 sm:space-x-4">
              <Button
                variant="outline"
                size="icon"
                onClick={() => {
                  if (audioRef.current) {
                    audioRef.current.currentTime = Math.max(
                      0,
                      audioRef.current.currentTime - 5
                    );
                  }
                }}
                className="w-8 h-8 sm:w-10 sm:h-10 rounded-full border text-gray-700 hover:bg-gray-50"
              >
                <SkipBack className="h-4 w-4" />
              </Button>
              <Button
                variant="default"
                size="icon"
                onClick={handlePlayPause}
                className={cn(
                  'rounded-full w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center transition-all duration-300',
                  isPlaying
                    ? 'bg-teal-600 text-white hover:bg-teal-700'
                    : 'bg-teal-600 text-white hover:bg-teal-700'
                )}
              >
                {isPlaying ? (
                  <Pause className="h-5 w-5 sm:h-6 sm:w-6" />
                ) : (
                  <Play className="h-5 w-5 sm:h-6 sm:w-6 ml-0.5" />
                )}
              </Button>
              <Button
                variant="outline"
                size="icon"
                onClick={() => {
                  if (audioRef.current) {
                    audioRef.current.currentTime = Math.min(
                      duration,
                      audioRef.current.currentTime + 5
                    );
                  }
                }}
                className="w-8 h-8 sm:w-10 sm:h-10 rounded-full border text-gray-700 hover:bg-gray-50"
              >
                <SkipForward className="h-4 w-4" />
              </Button>
            </div>
            <div className="flex items-center space-x-2">
              <Volume2 className="h-4 w-4 text-teal-600" />
              <Slider
                defaultValue={[volume]}
                max={100}
                step={1}
                onValueChange={handleVolumeChange}
                className="w-20 sm:w-24"
              />
            </div>
          </div>
          <audio
            ref={audioRef}
            src={recordedAudio}
            onTimeUpdate={handleTimeUpdate}
            onLoadedMetadata={handleLoadedMetadata}
            onEnded={() => setIsPlaying(false)}
          />
        </div>
      )}
    </div>
  );
}
